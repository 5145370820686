import React, {useState, useCallback, useEffect, useMemo} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useAccountApiContext} from "../../api/account/AccountApiContext";
import {update} from "immupdate";

import TabPage from "../tabs/TabPage";

import {useParcelApiContext} from "../../api/parcel/ParcelApiContext";
import {useMerchantApiContext} from "../../api/merchant/MerchantApiContext";
import {useCurrencyApiContext} from "../../api/currency/CurrencyApiContext";
import {useBranchApiContext} from "../../api/branch/BranchApiContext";
import CurrencyExchangeForm from "./CurrencyExchangeForm";

export default function CurrencyExchangeFormWrapper() {

    const {AccountApi} = useAccountApiContext();
    const {BranchApi} = useBranchApiContext();

    const {ParcelApi} = useParcelApiContext();
    const {MerchantApi} = useMerchantApiContext();
    const {CurrencyApi} = useCurrencyApiContext();
    const [paymentMethods, setPaymentMethods] = useState<any[]>([]);

    const [search, setSearch] = useSearchParams();
    const [senders, setSenders] = useState<any[]>([]);
    const [merchants, setMerchants] = useState<any[]>([]);
    const [currencyTypes, setCurrencyTypes] = useState<any[]>([]);
    const [branches, setBranches] = useState<any[]>([]);
    const [accountTypes, setAccountTypes] = useState<any[]>([]);
    const [currencyRate, setCurrencyRate] = useState<any[]>([]);

    const [accounts, setAccounts] = useState<any[]>([]);

    const location = useLocation();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        dateCreated: new Date(),
        fromAccountId: "",
        toAccountId: "",
        baseAmount: "",
        convertedAmount: "",
        currencyRate: "",
        toCurrencyId: "",
        fromCurrencyId: "",
    })

    // const accountTypes = [
    //     {
    //         label: "Branch",
    //         value: "Branch"
    //     },
    //     {
    //         label: "Merchant",
    //         value: "Merchant"
    //     },
    //     {
    //         label: "Customer",
    //         value: "Customer"
    //     },
    //     {
    //         label: "Staff",
    //         value: "Staff"
    //     },
    //     {
    //         label: "Manager",
    //         value: "Manager"
    //     },
    //     {
    //         label: "Courier",
    //         value: "Courier"
    //     },
    // ]

    useEffect(() => {
        AccountApi.getAccountTypeList()
            .then((response: any) => {
                response.accountsTypeList.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setAccountTypes((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setAccountTypes, AccountApi]);

    useEffect(() => {
        AccountApi.getAccountList()
            .then((response: any) => {
                response.accounts.map((item: any) => {
                    const data = {
                        label: item.name + " (" + item.balance+ " " + item.currencyCode + ")",
                        value: item.id,
                    };
                    setAccounts((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setAccounts, AccountApi]);

    const id = useMemo(() => search.get("currencyExchangeId"), [search]);

    useEffect(() => {
        if (id) {
            CurrencyApi.getCurrencyExchangeById(Number(id))
                .then((response: any) => {
                    var date = new Date(response.dateCreatedFor);
                    setInitialValues((prev: any) => update(prev, {
                        name: response.name,
                        number: response.number,
                        fromCurrencyId: {
                            label: response.fromCurrencyName,
                            value: response.fromCurrencyId
                        },
                        toCurrencyId: {
                            label: response.toCurrencyName,
                            value: response.toCurrencyId
                        },
                        fromAccountId: {
                            label: response.fromAccountName,
                            value: response.fromAccountId
                        },
                        toAccountId: {
                            label: response.toAccountName,
                            value: response.toAccountId
                        },
                        currencyRate: response.rate,
                        baseAmount: response.baseAmount,
                        convertedAmount: response.convertedAmount,
                        dateCreated: date
                    }))
                })
                .catch((err: any) => toast.error(err.message))
        }
    }, [id, setInitialValues, CurrencyApi]);


    useEffect(() => {
        CurrencyApi.getCurrencyTypeListByBranch()
            .then((response: any) => {
                response.currencies.map((item: any) => {
                    const data = {
                        label: item.code,
                        value: item.value,
                    };
                    setCurrencyTypes((currencies: any) => [...currencies, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setCurrencyTypes, CurrencyApi]);

    useEffect(() => {
        AccountApi.getAccountTypeList()
            .then((response: any) => {
                response.accountsTypeList.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setAccountTypes((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setAccountTypes, AccountApi]);


    useEffect(() => {
        BranchApi.getList()
            .then((response: any) => {
                response.branches.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setBranches((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setBranches, BranchApi]);


    const getSendersBySearching = useCallback(
        (value: string) => {
            if (value !== "") {
                ParcelApi.getSearchUserWithSkip(value)
                    .then((response: any) => {
                        let array: any = [];
                        // eslint-disable-next-line array-callback-return
                        response.customers.map((item: any) => {
                            const data = {
                                label: `${item.firstName} ${item.lastName} ${item.phone}`,
                                value: item.id,
                            };
                            array.push(data);
                        });
                        setSenders(array);
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            }
        },
        [setSenders, ParcelApi]
    );


    const merchantDisabled = useCallback((value: Number) => {
        if (value === 1 || value === 3 || value === 4 || value === 5 || value === 6) return true;
        else return false;
    }, []);

    const userDisabled = useCallback((value: Number) => {
        if (value === 2) return true;
        else return false;
    }, []);

    const userMerchantDisabled = useCallback((value: Number) => {
        if (value === 1) return true;
        else return false;
    }, []);

    //SUBMIT
    const submit = useCallback((value: any) => {
        // console.log(value)
        if (id) {
            const data = {
                ...value,
                id: Number(id),
                fromCurrencyId: value.fromCurrencyId.value,
                fromAccountId: value.fromAccountId.value,
                toCurrencyId: value.toCurrencyId.value,
                toAccountId: value.toAccountId.value,
                currencyRate: value.currencyRate,
                baseAmount: value.baseAmount,
                convertedAmount: value.convertedAmount
            }
            CurrencyApi.updateCurrencyExchange(data).then((response) => {
                toast.success(response.message);
                navigate('/app/money/tableConvertMoney')
            }).catch((error) => toast.error(error.message))
        } else {
            // console.log(value.accountTypeId)
            const data = {
                ...value,
                fromCurrencyId: value.fromCurrencyId.value,
                fromAccountId: value.fromAccountId.value,
                toCurrencyId: value.toCurrencyId.value,
                toAccountId: value.toAccountId.value,
                currencyRate: value.currencyRate,
                baseAmount: value.baseAmount,
                convertedAmount: value.convertedAmount
            }
            CurrencyApi.createCurrencyExchange(data).then((response) => {
                toast.success(response.message);
                navigate('/app/money/tableConvertMoney')
            }).catch((error) => toast.error(error.message))

        }
    }, [id, CurrencyApi, navigate])


    return (
        <TabPage
            childrenClassName="p-3 pt-4"
        >
            <CurrencyExchangeForm
                accounts={accounts}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                currencies={currencyTypes}
                submit={submit}/>
            <div/>
        </TabPage>
    )
}