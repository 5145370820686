import {BaseApi} from "../BaseApi";


export class MoveMoneyApi extends BaseApi {

    public getList(json: any) {
        return this.post("MoveMoney/list", {json});
    }

    public getDetails(id: any) {
        return this.get(`MoveMoney/${id}`);
    }

    public create(json: any) {
        return this.post("MoveMoney", {json});
    }

    public update(json: any) {
        return this.put("MoveMoney", {json});
    }

    public remove(id: any) {
        return this.delete(`MoveMoney/${id}`);
    }

}
