import {useParams} from "react-router-dom";

import ContainerLayout from "../components/app/ContainerLayout";
import MoveMoneyFormWrapper from "../components/money/MoveMoneyFormWrapper";
import MoveMoneyTableWrapper from "../components/money/MoveMoneyTableWrapper";
import CurrencyExchangeFormWrapper from "../components/currencyexchange/CurrencyExchangeFormWrapper";
import CurrencyExchangeTableWrapper from "../components/currencyexchange/CurrencyExchangeTableWrapper";
import TransactionMoneyTableWrapper from "../components/transactionmoney/TransactionMoneyTableWrapper";
import TransactionMoneyFormWrapper from "../components/transactionmoney/TransactionMoneyFormWrapper";

export default function TransactionMoney(){

    const { tab = "branch" } = useParams();

    return (
        <ContainerLayout>
            {tab === "formMoveMoney" && (
                <TransactionMoneyFormWrapper/>
            )}
            {tab === "tableMoveMoney" && (
                <TransactionMoneyTableWrapper/>
            )}
            {tab === "formConvertMoney" && (
                <TransactionMoneyFormWrapper/>
            )}
            {tab === "tableConvertMoney" && (
                <TransactionMoneyTableWrapper/>
            )}
        </ContainerLayout>
    )
}