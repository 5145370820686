import React, {useCallback} from "react";
import {Form, Formik} from "formik";
import {object, string} from "yup";
import {update} from "immupdate";
import DatePicker from "react-datepicker"

import GroupBox from "../app/GroupBox";
import InputField from "../form/InputField";
import Button from "../button/Button";
import SelectPickerField from "../form/SelectPickerField";
import Select from "react-select";

interface StatusFormProps {
    readonly initialValues: any;
    readonly selectedSender: any;
    readonly setInitialValues: (value: any) => void;
    readonly submit: (value: any) => void;
    readonly searchSender: (value: string) => void;
    readonly senders: any[];
    readonly branches: any[];
    readonly salaryTypes: any[];
    readonly formName: string;
}

const validationSchema = object({
    amount: string().required("Required!"),
    description: string(),
})

export default function AccountHistoryForm({
                                               initialValues,
                                               setInitialValues,
                                               searchSender,
                                               formName,
                                               submit,
                                               senders,
                                               selectedSender,
                                               salaryTypes,
                                               branches
                                           }: StatusFormProps) {

    const onChangeSenderId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                userId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeBranchId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                accountId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeSalaryTypeId = useCallback((value: any) => {
        console.log(value)
        setInitialValues((prev: any) =>
            update(prev, {
                salaryTypeId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeAmount = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                amount: value.target.value
            })
        )
    }, [setInitialValues])

    const onChangeDescription = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                description: value.target.value
            })
        )
    }, [setInitialValues])

    const onChangeDate = useCallback((date: Date) => {
        setInitialValues((prev: any) =>
            update(prev, {
                dateCreatedFor: date
            })
        )
    }, [setInitialValues]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={submit}
        >
            {() => (
                <Form>
                    <div className="row mt-3">
                        <div className="col-12">
                            <GroupBox title={formName}>
                                <div className="row">
                                    <div className="col-6">
                                        {/*<SelectPickerField*/}
                                        {/*    name="userId"*/}
                                        {/*    label="Пользователь"*/}
                                        {/*    options={senders}*/}
                                        {/*    onChanges={(value) => onChangeSenderId(value)}*/}
                                        {/*    id="userId"*/}
                                        {/*    isSearchable*/}
                                        {/*    onInputChange={(value) => searchSender(value)}*/}
                                        {/*/>*/}

                                        <label className={'mt-2'} htmlFor="userId">Пользователь</label>
                                        <Select
                                            id="userId"
                                            name="userId"
                                            options={senders}
                                            value={initialValues.userId}
                                            onChange={(value) => onChangeSenderId(value)}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <SelectPickerField
                                            name="accountId"
                                            label="Счет филиала"
                                            options={branches}

                                            onChanges={(value) => onChangeBranchId(value)}
                                            id="accountId"
                                        />
                                    </div>
                                    <div className="col-6 mt-3">
                                        {/*<SelectPickerField*/}
                                        {/*    name="salaryTypeId"*/}
                                        {/*    label="Тип затраты"*/}
                                        {/*    options={salaryTypes}*/}
                                        {/*    onChanges={(value) => onChangeSalaryTypeId(value)}*/}
                                        {/*/>*/}
                                        <label className={'mt-2'} htmlFor="salaryTypeId">Тип затраты</label>
                                        <Select
                                            name="salaryTypeId"
                                            options={salaryTypes}
                                            value={initialValues.salaryTypeId}
                                            onChange={(value) => onChangeSalaryTypeId(value)}
                                        />

                                    </div>
                                    <div className="col-3 mt-3">
                                        <InputField
                                            label="Сумма"
                                            name="amount"
                                            value={initialValues.amount}
                                            onChange={(event: any) => onChangeAmount(event)}
                                        />
                                    </div>
                                    <div className="col-3 mt-3">
                                        <label htmlFor="dateCreated" className="w-100">Дата</label>
                                        <DatePicker
                                            className={"form-control"}
                                            calendarClassName="w-100"
                                            showIcon
                                            dateFormat="dd.MM.yyyy"
                                            selected={initialValues.dateCreatedFor}
                                            onChange={(date: any) => onChangeDate(date)}
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <InputField
                                            label="Описание"
                                            name="description"
                                            value={initialValues.description}
                                            onChange={(event: any) => onChangeDescription(event)}
                                        />
                                    </div>
                                </div>
                            </GroupBox>
                        </div>
                        <div className="col-12 mt-3">
                            <Button type="submit" className="text-light bg-gold px-2 py-1">
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}