import {useCallback} from "react"
import {Form, Formik} from "formik";
import {ExportParcelProps} from "../../api/parcel/ParcelDto"
import {object} from "yup";
import GroupBox from "../app/GroupBox";
import SelectPickerField from "../form/SelectPickerField";
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css';
import {update} from "immupdate";
import Button from "../button/Button";

interface ExportParcelFormProps {
    readonly initialValues: ExportParcelProps;
    readonly setInitialValues: (value: any) => void;
    readonly onSubmit: (value: any) => void;
    readonly users: any[];
    readonly searchSender: (value: string) => void;
}

export interface SelectType {
    label: string;
    value: string;
}

const validationSchema = object({})

export default function ExportParcelFormByManager({
                                                      initialValues,
                                                      setInitialValues,
                                                      onSubmit,
                                                      users,
                                                      searchSender,
                                                  }: ExportParcelFormProps) {


    const onChangeRecipientStaffId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                recipientStaffId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);


    const onChangeToDate = useCallback((date: Date) => {
        setInitialValues((prev: any) =>
            update(prev, {
                toDate: date
            })
        )
    }, [setInitialValues]);


    const onChangeFromDate = useCallback((date: Date) => {
        setInitialValues((prev: any) =>
            update(prev, {
                fromDate: date
            })
        )
    }, [setInitialValues]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={() => onSubmit(initialValues)}
            enableReinitialize={true}
            validationSchema={validationSchema}>
            {() => (
                <Form>
                    <div className="row p-3 mt-3">
                        <div className="col-6">
                            <GroupBox title="Менеджер">
                                <div className="row mt-2">
                                    <div className="col-12 mt-2">
                                        <label htmlFor="recipientStaffId">ФИО менеджера</label>
                                        <SelectPickerField
                                            name="recipientStaffId"
                                            id="recipientStaffId"
                                            options={users}
                                            onChanges={(value) => onChangeRecipientStaffId(value)}
                                            onInputChange={(value) => searchSender(value)}
                                            isSearchable/>
                                    </div>
                                    <div className="col-6 mt-2">
                                        <label htmlFor="toDate" className="w-100">Дата (от)</label>
                                        <DatePicker
                                            className={"form-control"}
                                            calendarClassName="w-100"
                                            showIcon
                                            dateFormat="dd.MM.yyyy"
                                            selected={initialValues.fromDate}
                                            onChange={(date: any) => onChangeFromDate(date)}
                                        />
                                    </div>
                                    <div className="col-6 mt-2">
                                        <label htmlFor="toDate" className="w-100">Дата (до)</label>
                                        <DatePicker
                                            className={"form-control"}
                                            calendarClassName="w-100"
                                            showIcon
                                            dateFormat="dd.MM.yyyy"
                                            selected={initialValues.toDate}
                                            onChange={(date: any) => onChangeToDate(date)}
                                        />
                                    </div>
                                </div>
                            </GroupBox>
                        </div>
                        <div className="col-12 mt-3">
                            <Button
                                className="bg-black text-light px-3 py-1"
                                type="submit">
                                Скачать отчет
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}