import ContainerLayout from "../components/app/ContainerLayout";
import ExportParcelFormWrapperByCustomer from "../components/report/ExportParcelFormWrapperByCustomer";
import ExportParcelFormWrapperByCourier from "../components/report/ExportParcelFormWrapperByCourier";

export default function ExportParcelByCourierContainer(){
    return (
        <ContainerLayout>
            <ExportParcelFormWrapperByCourier/>
        </ContainerLayout>
    )
}