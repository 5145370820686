import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../button/Button";
import TabPage from "../tabs/TabPage";
import StatusForm from "./StatusForm";
import { useStatusApiContext } from "../../api/status/StatusApiContext";

interface StatusFormWrapperProps {
  readonly back: () => void;
}

export default function StatusFormWrapper({ back }: StatusFormWrapperProps) {
  const { StatusApi } = useStatusApiContext();
  const [search, setSearch] = useSearchParams();
  const id = useMemo(() => search.get("statusId"), [search]);

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    if (id) {
      StatusApi.getDetails(id)
        .then((response: any) => setInitialValues(response))
        .catch((err: any) => toast.error(err.message));
    }
  }, [id, setInitialValues, StatusApi]);

  const submit = useCallback(
    (value: any) => {
      if (id) {
        const data = {
          ...value,
          id: id,
        };
        StatusApi.update(data)
          .then((response) => {
            toast.success(response.message);
            setSearch({ pageType: "table" });
          })
          .catch((error) => toast.error(error.message));
      } else {
        const data = {
          ...value,
        };
        StatusApi.create(data)
          .then((response) => {
            toast.success(response.message);
            setSearch({ pageType: "table" });
          })
          .catch((error) => toast.error(error.message));
      }
    },
    [StatusApi, setSearch, id]
  );

  return (
    <TabPage
      childrenClassName="p-3 pt-4"
      headerComponent={
        <div className="d-flex align-items-center h-100">
          <Button onClick={back} className="bg-gold text-light px-2 py-1">
            Назад
          </Button>
        </div>
      }
    >
      <StatusForm
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        submit={submit}
      />
    </TabPage>
  );
}
