import {Form, Formik} from 'formik';
import {update} from 'immupdate';
import React, {useCallback, useMemo} from 'react';
import {bool, mixed, object, string} from 'yup';
import GroupBox from '../app/GroupBox';
import InputGroup from '../app/InputGroup';
import Button from '../button/Button';
import CheckBox from '../form/CheckBox';
import InputField from '../form/InputField';
import ImgUpload from '../app/ImgUpload';
import TextAreaField from '../form/TextAreaField';
import SelectPickerField from '../form/SelectPickerField';

import ParcelItemForm, {
    ItemsProps,
    ItemsStatusProps,
} from '../ui/ParcelItemForm';
import {CheckUserRole} from '../../utils/CheckUserInfo';
import {useShallowEqualSelector} from '../../hooks/useShallowSelector';
import {profileSelector} from '../../reducers/authReducer';
import Select from "react-select";

export interface SelectType {
    label: string;
    value: string;
}

const validationSchema = object({
    senderId: mixed<SelectType>(),
    recipientId: mixed<SelectType>(),
    parcelBranchFromId: mixed<SelectType>(),
    parcelBranchToId: mixed<SelectType>(),
    weight: string(),
    numberOfPoint: string(),
    parcelPlanId: mixed<SelectType>(),
    payerId: mixed<SelectType>(),
    costDeliveryToBranch: string(),
    costDeliveryToPoint: string(),
    costPickingUp: string(),
    senderCourierId: mixed<SelectType>(),
    recipientCourierId: mixed<SelectType>(),
    stateDeliveryToBranch: bool(),
    statePickingUp: bool(),
    stateDeliveryToPoint: bool(),
});

interface Props {
    readonly initialValues: any;
    readonly type: string;
    readonly printParcelItem: (value: any) => void;
    readonly setInitialValues: (value: any) => void;
    readonly senders: any[];
    readonly recipients: any[];
    readonly recipientStaffs: any[];
    readonly plans: any[];
    readonly parcelItemStatuses: any[];
    readonly customers: any[];
    readonly branches: any[];
    readonly payerTypes: any[];
    readonly parcelTypes: any[];
    readonly currencyTypes: any[];
    readonly merchants: any[];
    readonly accounts: any[];
    readonly costInfo: any[];
    readonly usdRate: any;
    readonly usdRateList: any[];
    readonly setRandomCode: (value: any) => void;
    readonly onSubmit: (value: any) => void;
    readonly searchSender: (value: string) => void;
    readonly searchRecipient: (value: string) => void;
    readonly searchRecipientStaff: (value: string) => void;
}

export default function TransactionForm({
                                            initialValues,
                                            recipients,
                                            recipientStaffs,
                                            senders,
                                            costInfo,
                                            usdRate,
                                            branches,
                                            merchants,
                                            accounts,
                                            payerTypes,
                                            currencyTypes,
                                            parcelTypes,
                                            plans,
                                            type,
                                            parcelItemStatuses,
                                            customers,
                                            printParcelItem,
                                            setInitialValues,
                                            setRandomCode,
                                            onSubmit,
                                            searchSender,
                                            searchRecipient,
                                            searchRecipientStaff,
                                        }: Props) {

    const profile: any = useShallowEqualSelector(profileSelector);

    const isAdminOrManager = useMemo(() => CheckUserRole(profile?.role, 'Administrator') ||
        CheckUserRole(profile?.role, 'Manager'), [profile.role]);

    const onChangeSenderId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                senderId: {
                    label: value.label,
                    value: value.value,
                },
            })
        );
    }, [setInitialValues]);

    const onChangeRecipientStaffId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                recipientStaffId: {
                    label: value.label,
                    value: value.value,
                },
            })
        );
    }, [setInitialValues]);

    const onChangeCustomSenderCourierId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                senderCourierId: {
                    label: value.label,
                    value: value.value,
                },
            })
        );
    }, [setInitialValues]);

    const onChangeCustomRecipientCourierId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                recipientCourierId: {
                    label: value.label,
                    value: value.value,
                },
            })
        );
    }, [setInitialValues]);

    const onChangeRecipientId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                recipientId: {
                    label: value.label,
                    value: value.value,
                },
            })
        );
    }, [setInitialValues]);

    const onChangeParcelBranchFromId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                parcelBranchFromId: {
                    label: value.label,
                    value: value.value,
                },
            })
        );
    }, [setInitialValues, initialValues]);

    const onChangeNumberOfPoint = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                numberOfPoint: value,
            })
        );
    }, [setInitialValues, initialValues]);

    const onChangeParcelBranchToId = useCallback((value: any) => {

        // const { COST_USD, CURRENCY_NAME, CURRENCY_ID } = calculateCost(initialValues.weight, costInfo, { ...initialValues, parcelBranchToId: { value: value.value } }, usdRate);

        setInitialValues((prev: any) =>
            update(prev, {
                parcelBranchToId: {
                    label: value.label,
                    value: value.value,
                },
                // costDeliveryToBranch: COST_USD,
                currencyTypeIdForDeliveryToBranch: {
                    label: 1,
                    value: 1,
                },
            })
        );
    }, [setInitialValues, initialValues, costInfo, usdRate]);

    const onChangeWeight = useCallback((value: any) => {
        // const { COST_USD, CURRENCY_NAME, CURRENCY_ID } = calculateCost(value, costInfo, initialValues, usdRate);

        setInitialValues((prev: any) =>
            update(prev, {
                weight: value,
                // costDeliveryToBranch: COST_USD,
                currencyTypeIdForDeliveryToBranch: {
                    label: 1,
                    value: 1,
                },
            })
        );
    }, [setInitialValues, initialValues, costInfo, usdRate]);

    const onChangeParcelPlanId = useCallback((value: any) => {
        // const { COST_USD, CURRENCY_NAME, CURRENCY_ID } = calculateCost(initialValues.weight, costInfo, { ...initialValues, parcelPlanId: { value: value.value } }, usdRate);

        setInitialValues((prev: any) =>
            update(prev, {
                parcelPlanId: {
                    label: value.label,
                    value: value.value,
                },
                // costDeliveryToBranch: COST_USD,
                currencyTypeIdForDeliveryToBranch: {
                    label: 1,
                    value: 1,
                },
            })
        );
    }, [setInitialValues, initialValues, costInfo, usdRate]);

    ////ParcelCost
    const onChangeCostDeliveryToBranchUsd = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                costDeliveryToBranch: value.target.value.replace(/\,/, ".").replace(/ /g, ""),
            })
        );
    }, [setInitialValues, initialValues, costInfo]);

    const onChangeStateDeliveryToBranchUsd = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                stateDeliveryToBranch: value
            })
        );

    }, [setInitialValues]);

    const onChangeAccountIdForCostDeliveryToBranch = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                accountIdForCostDeliveryToBranch: {
                    label: value.label,
                    value: value.value,
                },
            })
        );
    }, [setInitialValues]);

    const onChangeCostDeliveryToPointUsd = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                costDeliveryToPoint: value.target.value.replace(/\,/, ".").replace(/ /g, ""),
            })
        );
    }, [setInitialValues, initialValues, costInfo]);

    const onChangeStateDeliveryToPointUsd = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                stateDeliveryToPoint: value
            })
        );

    }, [setInitialValues]);

    const onChangeAccountIdForCostDeliveryToPoint = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                accountIdForCostDeliveryToPoint: {
                    label: value.label,
                    value: value.value,
                },
            })
        );
    }, [setInitialValues]);

    const onChangeCostPickingUpUsd = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                costPickingUp: value.target.value.replace(/\,/, ".").replace(/ /g, ""),
            })
        );
    }, [setInitialValues, initialValues, costInfo]);

    const onChangeStatePickingUpUsd = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                statePickingUp: value
            })
        );

    }, [setInitialValues]);

    const onChangeAccountIdForCostPickingUp = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                accountIdForCostPickingUp: {
                    label: value.label,
                    value: value.value,
                },
            })
        );
    }, [setInitialValues]);

    const onChangeDescription = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                description: value.target.value,
            })
        );
    }, [setInitialValues]);

    const onChangeDeliveryAddress = useCallback((value: any) => {

        setInitialValues((prev: any) =>
            update(prev, {
                deliveryAddress: value.target.value,
            })
        );
    }, [setInitialValues]);

    // ==== Image ==== //

    const onChangeImage = useCallback((value: any) => {

        let arr: any = [...initialValues.images];

        for (let i = 0; i < value.target.files.length; i++) {

            let imageFile = value.target.files[0];

            const reader = new FileReader();

            reader.onloadend = (x) => {
                const data = {
                    imageBytes: reader.result?.toString(),
                    imageName: '',
                    imageSrc: x.target?.result,
                };
                arr.push(data);
                setInitialValues((prev: any) =>
                    update(prev, {
                        images: arr,
                        imageSrc: x.target?.result
                    })
                );
            };

            reader.readAsDataURL(value.target.files[i]);
        }
    }, [setInitialValues, initialValues]);

    const deleteImage = useCallback((value: number) => {
        const images = [...initialValues.images];
        images.splice(value, 1);
        setInitialValues((prev: any) =>
            update(prev, {
                images: images,
            })
        );
    }, [setInitialValues, initialValues.images]);

    const onChangeMerchantId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                merchantId: {
                    label: value.label,
                    value: value.value,
                },
            })
        );
    }, [setInitialValues]);

    const onChangePayerType = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                payerTypeId: {
                    label: value.label,
                    value: value.value,
                }
                // ,
                // payerId:
                //     value.value === 'sender'
                //         ? initialValues.senderId.value
                //         : value.value === 'recipient'
                //             ? initialValues.recipientId.value
                //             : '',
            })
        );
    }, [setInitialValues, initialValues.senderId, initialValues.recipientId]);


    const onChangeParcelTypeId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                parcelsTypeId: {
                    label: value.label,
                    value: value.value,
                },
            })
        );
    }, [setInitialValues]);

    const onChangeCurrencyTypeDeliveryToBranch = useCallback((value: any) => {
        let localCost: any;

        if (value.value !== 1) {
            localCost = initialValues.costDeliveryToBranch as any * usdRate // Explicitly specify the type
        } else if (value.value == 1) {
            localCost = initialValues.costDeliveryToBranch as any / usdRate // Explicitly specify the type
        }

        setInitialValues((prev: any) =>
            update(prev, {
                currencyTypeIdForDeliveryToBranch: {
                    label: value.label,
                    value: value.value,
                },

                costDeliveryToBranch: localCost.toFixed(2)
            })
        );
    }, [setInitialValues, initialValues, usdRate]);

    const onChangeCurrencyTypePickingUp = useCallback((value: any) => {

        let localCost: any;
        //Convert to Local
        if (value.value !== 1) {
            localCost = initialValues.costPickingUp as any * usdRate
        }

        if (value.value == 1) {
            console.log((usdRate - ((2 / 100) * usdRate)))
            localCost = initialValues.costPickingUp as any / (usdRate - ((2 / 100) * usdRate))
        }

        setInitialValues((prev: any) =>
            update(prev, {
                currencyTypeIdForPickingUp: {
                    label: value.label,
                    value: value.value,
                },
                costPickingUp: localCost.toFixed(2)
            })
        );
    }, [setInitialValues, initialValues, usdRate, initialValues.costPickingUp]);

    const onChangeCurrencyTypeDeliveryToPoint = useCallback((value: any) => {

        let localCost: any;

        if (value.value !== 1) {
            localCost = initialValues.costDeliveryToPoint as any * usdRate
        } else if (value.value == 1) {
            localCost = initialValues.costDeliveryToPoint as any / (usdRate - ((2 / 100) * usdRate))
        }

        setInitialValues((prev: any) =>
            update(prev, {
                currencyTypeIdForDeliveryToPoint: {
                    label: value.label,
                    value: value.value,
                },
                costDeliveryToPoint: localCost.toFixed(2)
            })
        );
    }, [setInitialValues, initialValues, usdRate]);

    const addParcelItem = useCallback(() => {

        const data = [...initialValues.parcelItem];

        const parcelItemStatus: ItemsStatusProps = {
            id: 0,
            statusItemId: 1,
            dateCreated: new Date().toDateString(),
            isCurrent: true,
            statusItemName: 'Создан',
        };

        // function randomNumber(length: Number) {
        //     var text = "";
        //     var possible = "123456789";
        //     for (var i = 0; i < length; i++) {
        //         var sup = Math.floor(Math.random() * possible.length);
        //         text += i > 0 && sup == i ? "0" : possible.charAt(sup);
        //     }
        //     return Number(text);
        // }

        const parcelItem: ItemsProps = {
            id: 0,
            // code: Math.floor(
            //     Math.random() * (199999999 - 100000000 + 1) + 100000000
            // ),

            // code: Math.floor(Math.random()*1000000000),
            // code: Math.floor(Math.random() * 900000000) + 100000000,
            // code: randomNumber(9),
            code: Math.floor(100000000 + Math.random() * 900000000),
            parcelItemStatus: [parcelItemStatus],
        };

        // console.log(parcelItem.code)
        data.push(parcelItem);

        setInitialValues((prev: any) =>
            update(prev, {
                parcelItem: data,
            })
        );
    }, [setInitialValues, initialValues.parcelItem]);

    const removeParcelItem = useCallback((index: any) => {
        const data = [...initialValues.parcelItem];
        data.splice(index, 1);
        setInitialValues((prev: any) =>
            update(prev, {
                parcelItem: data,
            })
        );
    }, [setInitialValues, initialValues.parcelItem]);

    const onChangeParcelItem = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                parcelItem: value,
            })
        );
    }, [setInitialValues]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
        >
            {() => (
                <Form>
                    <div className="row p-3 mt-3">
                        <div className="col-6">
                            <GroupBox title="Отправитель">

                                <div className="row mt-2">
                                    <div className="col-12">

                                        {/*<Select*/}
                                        {/*    id="senderId"*/}
                                        {/*    name="senderId"*/}
                                        {/*    options={senders}*/}
                                        {/*    value={initialValues.senderId}*/}
                                        {/*    onChange={(value) => onChangeSenderId(value)}*/}
                                        {/*/>*/}

                                        <SelectPickerField
                                            name="senderId"
                                            options={senders}
                                            onChanges={(value) =>
                                                onChangeSenderId(value)
                                            }
                                            id="senderId"
                                            label="ФИО отправителя"
                                            isSearchable
                                            onInputChange={(value) =>
                                                searchSender(value)
                                            }
                                        />


                                    </div>
                                    <div className="col-12 mt-2">
                                        <SelectPickerField
                                            name="parcelBranchFromId"
                                            disabled
                                            options={branches}
                                            onChanges={(value: any) =>
                                                onChangeParcelBranchFromId(
                                                    value
                                                )
                                            }
                                            label="Откуда"
                                        />
                                    </div>
                                </div>
                            </GroupBox>
                        </div>
                        <div className="col-6">
                            <GroupBox title="Получатель">
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <SelectPickerField
                                            isSearchable={true}
                                            name="recipientId"
                                            options={recipients}
                                            onChanges={(value) =>
                                                onChangeRecipientId(value)
                                            }
                                            label="ФИО получателя"
                                            onInputChange={(value) =>
                                                searchRecipient(value)
                                            }
                                        />
                                    </div>
                                    <div className="col-12 mt-2">
                                        <SelectPickerField
                                            name="parcelBranchToId"
                                            options={branches}
                                            onChanges={(value: any) =>
                                                onChangeParcelBranchToId(value)
                                            }
                                            label="Куда"
                                        />
                                    </div>
                                </div>
                            </GroupBox>
                        </div>
                        {/*<div className="col-6 mt-4">*/}
                        {/*    <GroupBox title="Менеджер отправитель">*/}
                        {/*        <div className="row mt-2">*/}
                        {/*            <div className="col-12">*/}
                        {/*                <SelectPickerField*/}
                        {/*                    name="recipientStaffId"*/}
                        {/*                    options={recipientStaffs}*/}
                        {/*                    onChanges={(value) =>*/}
                        {/*                        onChangeRecipientStaffId(value)*/}
                        {/*                    }*/}
                        {/*                    id="recipientStaffId"*/}
                        {/*                    label="ФИО ответственного менеджера"*/}
                        {/*                    isSearchable*/}
                        {/*                    onInputChange={(value) =>*/}
                        {/*                        searchRecipientStaff(value)*/}
                        {/*                    }*/}
                        {/*                />*/}
                        {/*            </div>*/}

                        {/*        </div>*/}
                        {/*    </GroupBox>*/}
                        {/*</div>*/}
                        {/*<div className="col-6 mt-4">*/}
                        {/*    <GroupBox title="Менеджер получатель">*/}
                        {/*        <div className="row mt-2">*/}
                        {/*            <div className="col-12">*/}
                        {/*                <SelectPickerField*/}
                        {/*                    name="senderStaffId"*/}
                        {/*                    options={recipientStaffs}*/}
                        {/*                    onChanges={(value) =>*/}
                        {/*                        onChangeRecipientStaffId(value)*/}
                        {/*                    }*/}
                        {/*                    disabled={true}*/}
                        {/*                    id="senderStaffId"*/}
                        {/*                    label="ФИО ответственного менеджера"*/}
                        {/*                    isSearchable*/}
                        {/*                    onInputChange={(value) =>*/}
                        {/*                        searchRecipientStaff(value)*/}
                        {/*                    }*/}
                        {/*                />*/}
                        {/*            </div>*/}

                        {/*        </div>*/}
                        {/*    </GroupBox>*/}
                        {/*</div>*/}
                        <InputField name="costPickingUp_parcelCostId" value={initialValues.costPickingUp_parcelCostId}
                                    type="hidden"/>
                        <InputField name="costDeliveryToBranch_parcelCostId"
                                    value={initialValues.costDeliveryToBranch_parcelCostId} type="hidden"/>
                        <InputField name="costDeliveryToPoint_parcelCostId"
                                    value={initialValues.costDeliveryToPoint_parcelCostId} type="hidden"/>

                        <div className="col-12 mt-4">
                            <GroupBox title="Сумма перевода" colorName='red'>
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <InputGroup label="Получено от отправителя">
                                            <CheckBox
                                                onChange={(event) =>
                                                    onChangeStateDeliveryToBranchUsd(event)
                                                }
                                                value={
                                                    initialValues.stateDeliveryToBranch
                                                }
                                                disabled={
                                                    !isAdminOrManager &&
                                                    type === 'update'
                                                }
                                                name="stateDeliveryToBranch"
                                            />
                                            <InputField
                                                value={
                                                    initialValues.costDeliveryToBranch
                                                }
                                                type="text"
                                                onChange={(event: any) =>
                                                    onChangeCostDeliveryToBranchUsd(event)
                                                }
                                                inputClassName="rounded-0 border-0 h-100"
                                                name="costDeliveryToBranch"
                                            />
                                        </InputGroup>
                                    </div>

                                    {/*Стоимость перевозки до филиала в местной валюте*/}
                                    <div className="col-1">
                                        <SelectPickerField
                                            name="currencyTypeIdForDeliveryToBranch"
                                            onChanges={(value: any) =>
                                                onChangeCurrencyTypeDeliveryToBranch(value)
                                            }
                                            options={currencyTypes}
                                            label="Валюта"
                                        />
                                    </div>

                                    <div className="col-1">
                                        <InputField
                                            className={"rounded-0 border-1 h-100"}
                                            label={"Комиссия"}
                                            value={initialValues.costPickingUp}
                                            type="text"
                                            onChange={(event: any) => onChangeCostPickingUpUsd(event)}
                                            name="costPickingUp"
                                        />
                                    </div>

                                    <div className="col-6">
                                        <SelectPickerField
                                            options={accounts}
                                            onChanges={(value: any) =>
                                                onChangeAccountIdForCostDeliveryToBranch(
                                                    value
                                                )
                                            }
                                            name="accountIdForCostDeliveryToBranch"
                                            label="Счет филиала"
                                        />
                                    </div>
                                </div>
                            </GroupBox>
                        </div>
                        {/*Стоимость забора*/}
                        <div className="col-12 mt-4">
                            <GroupBox title="Стоимость забора">
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <InputGroup label="Забор">
                                            <CheckBox
                                                onChange={(event) => onChangeStatePickingUpUsd(event)}
                                                value={
                                                    initialValues.statePickingUp
                                                }
                                                disabled={
                                                    !isAdminOrManager &&
                                                    type === 'update'
                                                }
                                                name="statePickingUp"
                                            />
                                            <InputField
                                                value={initialValues.costPickingUp}
                                                type="text"
                                                onChange={(event: any) => onChangeCostPickingUpUsd(event)}
                                                inputClassName="rounded-0 border-0 h-100"
                                                name="costPickingUp"
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className="col-2">
                                        <SelectPickerField
                                            name="currencyTypeIdForPickingUp"
                                            onChanges={(value: any) =>
                                                onChangeCurrencyTypePickingUp(value)
                                            }
                                            options={currencyTypes}
                                            label="Валюта"
                                        />
                                    </div>


                                    <div className="col-6">
                                        <SelectPickerField
                                            options={accounts}
                                            onChanges={(value: any) =>
                                                onChangeAccountIdForCostPickingUp(value)
                                            }
                                            name="accountIdForCostPickingUp"
                                            label="Счет филиала"
                                        />
                                    </div>
                                </div>
                            </GroupBox>
                        </div>

                        {/*Стоимость доставки до двери*/}
                        <div className="col-12 mt-4">
                            <GroupBox title="Стоимость доставки до двери">
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <InputGroup label="Доставка">
                                            <CheckBox
                                                onChange={(event) =>
                                                    onChangeStateDeliveryToPointUsd(event)
                                                }
                                                value={
                                                    initialValues.stateDeliveryToPoint
                                                }
                                                disabled={
                                                    !isAdminOrManager &&
                                                    type === 'update'
                                                }
                                                name="stateDeliveryToPoint"
                                            />
                                            <InputField
                                                value={
                                                    initialValues.costDeliveryToPoint
                                                }
                                                type="text"
                                                onChange={(event: any) =>
                                                    onChangeCostDeliveryToPointUsd(event)
                                                }
                                                inputClassName="rounded-0 border-0 h-100"
                                                name="costDeliveryToPoint"
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className="col-2">
                                        <SelectPickerField
                                            name="currencyTypeIdForDeliveryToPoint"
                                            onChanges={(value: any) =>
                                                onChangeCurrencyTypeDeliveryToPoint(value)
                                            }
                                            options={currencyTypes}
                                            label="Валюта"
                                        />
                                    </div>
                                    <div className="col-6">
                                        <SelectPickerField
                                            options={accounts}
                                            onChanges={(value: any) =>
                                                onChangeAccountIdForCostDeliveryToPoint(value)
                                            }
                                            name="accountIdForCostDeliveryToPoint"
                                            label="Счет филиала"
                                        />
                                    </div>
                                </div>
                            </GroupBox>

                        </div>


                        {type !== "create" && (
                            <div className="col-12 mt-4">
                                <GroupBox>
                                    <ParcelItemForm
                                        parcelItems={initialValues.parcelItem}
                                        parcelItemStatuses={parcelItemStatuses}
                                        onChangeValue={(value) =>
                                            onChangeParcelItem(value)
                                        }
                                        addItem={addParcelItem}
                                        printParcelItem={printParcelItem}
                                        removeItem={(index) =>
                                            removeParcelItem(index)
                                        }
                                        type={type}
                                    />
                                </GroupBox>
                            </div>
                        )}

                        {/*Адреса доставки и забора посылки*/}
                        <div className="col-12 mt-4">
                            <GroupBox title="Описание перевода">
                                <div className="row">
                                    <div className="col-12 mt-2">
                                        <TextAreaField
                                            label="Описание"
                                            name="description"
                                            value={initialValues.description}
                                            onChange={(value: any) =>
                                                onChangeDescription(value)
                                            }
                                        />
                                    </div>
                                </div>
                            </GroupBox>
                        </div>

                        <div className="col-12 mt-3 d-flex gap-3">
                            <Button
                                className="bg-gold text-light px-3 py-1"
                                type="submit"
                            >
                                Сохранить
                            </Button>
                            {type !== 'create' && (
                                <Button
                                    className="text-light bg-green px-3 py-1"
                                    onClick={() => setRandomCode(initialValues)}
                                >
                                    Печать этикетки
                                </Button>
                            )}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
