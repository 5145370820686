import React, {useCallback, useEffect, useState} from "react";
import TabPage from "../tabs/TabPage";
import {ExportParcelProps} from "../../api/parcel/ParcelDto";
import {toast} from "react-toastify";
import {useParcelApiContext} from "../../api/parcel/ParcelApiContext";
import moment from "moment-timezone";
import {useBranchApiContext} from "../../api/branch/BranchApiContext";
import {usePlanApiContext} from "../../api/plan/PlanApiContext";
import {useStatusApiContext} from "../../api/status/StatusApiContext";
import ModalLoader from "../modal/MdalLoader";
import ExportParcelFormByManager from "./ExportParcelFormByManager";
import ExportParcelFormByStaff from "./ExportParcelFormByStaff";

export default function ExportParcelFormWrapperByStaff() {
  const [initialValues, setInitialValues] = useState<ExportParcelProps>({
    senderId: {
      label: "",
      value: "",
    },
    recipientId: {
      label: "",
      value: "",
    },
    recipientCourierId: {
      label: "",
      value: "",
    },
    senderCourierId: {
      label: "",
      value: "",
    },
    senderStaffId: {
      label: "",
      value: "",
    },
    recipientStaffId: {
      label: "",
      value: "",
    },
    planId: {
      label: "",
      value: "",
    },
    statusId: {
      label: "",
      value: "",
    },
    fromBranchId: {
      label: "",
      value: "",
    },
    toBranchId: {
      label: "",
      value: "",
    },
    fromDate: new Date(),
    toDate: new Date(),
    statusDate: new Date()
  });
  const [senders, setSenders] = useState<any[]>([]);
  const [staffs, setStaffs] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { ParcelApi } = useParcelApiContext();

  const getSendersBySearching = useCallback((value: string) => {
    if (value !== "") {
      ParcelApi.getSearchUserWithSkip(value)
          .then((response: any) => {
            let array: any = [];
            response.customers.map((item: any) => {
              const data = {
                label: `${item.firstName} ${item.lastName} ${item.phone}`,
                value: item.id,
              };
              array.push(data);
            });
            setSenders(array);
          })
          .catch((error) => {
            toast.error(error.message);
          });
    }
  },[ParcelApi]);

  const onSubmit = useCallback((value: any) => {
    const data = {
      senderId: value.senderId.value !== "" ? value.senderId.value : 0,
      recipientId: value.recipientId.value !== "" ? value.recipientId.value : 0,
      recipientCourierId: value.recipientCourierId.value !== "" ? value.recipientCourierId.value : 0,
      senderCourierId: value.senderCourierId.value !== "" ? value.senderCourierId.value : 0,
      senderStaffId: value.senderStaffId.value !== "" ? value.senderStaffId.value : 0,
      recipientStaffId: value.recipientStaffId.value !== "" ? value.recipientStaffId.value : 0,
      planId: value.planId.value !== "" ? value.planId.value : 0,
      statusId: value.statusId.value !== "" ? value.statusId.value : 0,
      fromBranchId: value.fromBranchId.value !== "" ? value.fromBranchId.value : 0,
      toBranchId: value.toBranchId.value !== "" ? value.toBranchId.value : 0,
      fromDate: moment(value.fromDate).format("YYYY-MM-DD"),
      toDate: moment(value.toDate).format("YYYY-MM-DD"),
    };
    setLoading(true);
    ParcelApi.exportParcels(data)
        .finally(() => {
          setLoading(false);
        });

  }, [ParcelApi]);

  useEffect(() => {
    ParcelApi.getRoles(3)
        .then((response: any) => {
          // eslint-disable-next-line array-callback-return
          response.customers.map((item: any) => {
            const data = {
              label: `${item.firstName} ${item.lastName} ${item.phone}`,
              value: item.id,
            };
            setStaffs((prev: any) => [...prev, data]);
          });
        })
        .catch((error) => toast.error(error.message));
  }, [setStaffs, ParcelApi]);

  return (
    <TabPage childrenClassName="p-2" className="p-4">
      <ExportParcelFormByStaff
        users={staffs}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        onSubmit={onSubmit}
        searchSender={(value: string) => getSendersBySearching(value)}
      />

      <ModalLoader open={loading}/>
    </TabPage>

  );
}
