export enum Pages {
  Login = "/auth",
  App = "/app",
}

export enum RouteContainerTabs {
  Administrator = "administrator",
  Dashboard = "dashboard",
  Users = "users",
  Parcels = "parcels",

  Reports = "reports",

  AddParcelSecond = "parcel-form",
  AddManifest = "manifest-form",
  AddUser = "add-user",
  EditParcel = "edit-parcel",

  ExportParcel = "export-parcel",
  ExportParcelByCustomer = "export-parcel-by-customer",
  ExportParcelByManager = "export-parcel-by-manager",
  ExportParcelByStaff = "export-parcel-by-staff",
  ExportParcelByCourier = "export-parcel-by-courier",
  ExportParcelByMerchant = "export-parcel-by-merchant",
  ExportExpenses = "export-parcel-expenses",

  Salary = "salary",
  Incomes = "incomes",
  Expense = "expense",
  Currency = "currency",
  Merchant = "merchant",
  Account = "account",
  Money = "money",
  AccountHistoryById = "accountHistory",
  TransactionMoney = "transactionmoney",

  Forbidden = "forbidden",
  IssuedInvoice = "issued-invoice",
  Manifest = "manifest",
  Orders = "orders",
  Couriers = "couriers",
  Customers = "customers",
  CustomerParcels = "custome-parcels",
  CustomerOrders = "customer-orders",
  IncomeParcel = "accounthistory",

  AdministratorPath = "administrator/:tab?",
  UsersPath = "users/:tab?",
  ParcelsPath = "parcels/:tab?",
  AddParcelPath = "add-parcel/:tab?",
  EditParcelPath = "edit-parcel/:tab?",
  EditParcelSecondPath = "print-invoice/:tab?",
  ExportParcelPath = "export-parcel/:tab?",

  ExportParcelByCustomerPath = "export-parcel-by-customer/:tab?",
  ExportParcelByCourierPath = "export-parcel-by-courier/:tab?",
  ExportParcelByManagerPath = "export-parcel-by-manager/:tab?",
  ExportParcelByStaffPath = "export-parcel-by-staff/:tab?",
  ExportParcelByMerchantPath = "export-parcel-by-merchant/:tab?",
  ExportParcelByBranchPath = "export-parcel-by-branch/:tab?",
  ExportExpensesPath =  "export-parcel-expenses/:tab?",

  SalaryPath = "salary/:tab?",
  IncomesPath = "incomes/:tab?",
  ExpensePath = "expense/:tab?",
  CurrencyPath = "currency/:tab?",
  MerchantPath = "merchant/:tab?",
  AccountPath = "account/:tab?",
  MoneyPath = "money/:tab?",
  AccountHistoryByIdPath = "accountHistory/:tab?",

  TransactionMoneyPath = "transactionmoney/:tab?",
  IssuedInvoicePath = "issued-invoice/:tab?",
  ManifestPath = "manifest/:tab?",
  OrdersPath = "orders/:tab?",
  CouriersPath = "couriers/:tab?",
  CustomersPath = "customers/:tab?",
  CustomerParcelsPath = "custome-parcels/:tab?",
  CustomerOrdersPath = "customer-orders/:tab?",
}
