import React, {useCallback, useMemo, useRef, useState} from "react";
import DeleteIcon from "../icons/DeleteIcon";
import EditIcon from "../icons/EditIcon";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";
import {useNavigate} from "react-router-dom";
import {useAccountApiContext} from "../../api/account/AccountApiContext";
import {toast} from "react-toastify";
import {ISetFilter, PaginationNumberFormatterParams} from "ag-grid-community";
import {IDateFilterParams} from "@ag-grid-community/core";
import {AgGridReact} from "ag-grid-react";
import moment from "moment-timezone";
import {useAccountHistoryApiContext} from "../../api/accounthistory/AccountHistoryApiContext";
import {useMoveMoneyApiContext} from "../../api/money/MoveMoneyApiContext";

export default function MoveMoneyTable() {

    const profile: any = useShallowEqualSelector(profileSelector);
    const navigator = useNavigate();
    const {AccountApi} = useAccountApiContext();
    const {MoveMoneyApi} = useMoveMoneyApiContext();
    const {AccountHistoryApi} = useAccountHistoryApiContext();

    const checkRole = useCallback(
        (role: string) => {
            const hasRole = profile.role.filter((ro: string) => ro === role);
            return hasRole.length > 0 ? true : false;
        },
        [profile]
    );
    function areEqual(a: null | string[], b: null | string[]) {
        if (a == null && b == null) {
            return true;
        }
        if (a != null || b != null) {
            return false;
        }
        return (
            a!.length === b!.length &&
            a!.every(function (v, i) {
                return b![i] === v;
            })
        );
    }

    const editButton = (params: any) => {
        navigator(`/app/money/formMoveMoney?moveMoneyId=${params.data.id}`)
    }

    const openModal = (params: any) => {
        const userConfirmed = window.confirm("Are you sure you want to delete?");
        if (userConfirmed) {
            deleteAccountHistory(params.data.id);
        }
    };

    const deleteAccountHistory = useCallback((id: any) => {
        MoveMoneyApi.remove(id)
            .then((response) => {
                toast.success(response.message);
                window.location.reload();
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }, [AccountHistoryApi]);

    const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
        return [20, 50, 100, 10000];
    }, []);

    const paginationNumberFormatter = useCallback(
        (params: PaginationNumberFormatterParams) => {
            return "[" + params.value.toLocaleString() + "]";
        },
        [],
    );

    const [gridApi, setGridApi] = useState(null);
    var dateFilterParams: IDateFilterParams = {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split(".");
            var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0]),
            );
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        minValidYear: 2024,
        maxValidYear: 2025,
        inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    };
    const onGridReady = (params: any) => {
        setGridApi(params);
        params.api.updateGridOptions({serverSideDatasource: datasource})
    }
    const gridOptions = {
        // other grid options ...
        getRowId: (params: any) => {
            // return the ID for the row data item
            return params.data.id;
        },
    };
    const gridRef = useRef<AgGridReact>(null);

    const [initialValues, setInitialValues] = useState({
        code: "",
        userName: "",
        fromDate: "",
        toDate: "",
        type: "",
        accountNumber: "",
    });

    var selectedBranchName: string[] | null = null;

    const onFilterChanged = useCallback(() => {
        // fromBranch
        var fromBranchFilterModel = gridRef.current!.api.getFilterModel()["branchName"];
        var selectedF = fromBranchFilterModel && fromBranchFilterModel.values;
        if (!areEqual(selectedBranchName, selectedF)) {
            selectedBranchName = selectedF;
            gridRef.current!.api.getColumnFilterInstance<ISetFilter>("branchName")
                .then((branchName: any) => {
                    branchName!.refreshFilterValues();
                });
        }

    }, [selectedBranchName]);

    const datasource = {
        getRows(params: any) {
            const {startRow, endRow, filterModel, sortModel} = params.request
            let count = params.request.endRow - params.request.startRow; // or the cacheBlockSize
            let page = Math.ceil(params.request.endRow / count);

            //RecipientCourier
            const filterKeysForUser = Object.keys(filterModel)
            filterKeysForUser.forEach(filter => {
                if (filterKeysForUser[0] === "userName") {
                    initialValues.userName = "";
                    initialValues.userName += `${filterModel[filter].filter}`
                }
            })

            //Type
            const filterKeysForType = Object.keys(filterModel)
            filterKeysForType.forEach(filter => {
                if (filterKeysForType[0] === "expenseTypeName") {
                    initialValues.type = "";
                    initialValues.type += `${filterModel[filter].filter}`
                }
            })

            //AccountNumber
            const filterKeysForAccountNumber = Object.keys(filterModel)
            filterKeysForAccountNumber.forEach(filter => {
                if (filterKeysForAccountNumber[0] === "branchAccountNumber") {
                    initialValues.accountNumber = "";
                    initialValues.accountNumber += `${filterModel[filter].filter}`
                }
            })

            const filterKeysFromDate = Object.keys(filterModel)
            filterKeysFromDate.forEach(filter => {
                if (filterKeysFromDate[0] === "dateCreated") {
                    if (filterModel[filter].dateFrom !== null) {
                        initialValues.fromDate = "";
                        initialValues.fromDate += `${filterModel[filter].dateFrom}`
                    }
                    if (filterModel[filter].dateTo !== null) {
                        initialValues.toDate = "";
                        initialValues.toDate += `${filterModel[filter].dateTo}`
                    }
                }
            })

            const data = {
                pageNumber: page,
                pageSize: count === 0 ? 1 : count,
                // userName: initialValues.userName,
                accountNumber: initialValues.accountNumber,
                // listType: "expense",
                // type: initialValues.type,
                branchName: selectedBranchName || ['Москва', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул', 'Ташкент'],
                dateCreateStart: initialValues.fromDate,
                dateCreateEnd: initialValues.toDate,
            };

            MoveMoneyApi.getList(data)
                .then((response: any) => {
                    params.success({
                        rowData: response.items,
                        rowCount: response.items.totalCount,
                    });
                    initialValues.userName = ""
                    initialValues.accountNumber = ""
                     initialValues.type = ""

                })
                .catch((error: any) => {
                    if (error.message === '') {
                        toast.error('Расход не найден!');
                    } else {
                        toast.error(error.message);
                    }
                });
        }
    };

    const [columnDefs, setColDefs] = useState([
        {
            field: "id",
            headerName: "№",
            minWidth: 70,
            maxWidth: 70,
            // filter: "agNumberColumnFilter"
        },
        {
            headerName: "Дата",
            field: "dateCreated",
            lockPinned: true,
            filter: "agDateColumnFilter",
            filterParams: dateFilterParams,
            minWidth: 140,
            maxWidth: 140,
            cellRenderer: function (row: any) {
                return moment(row.data.dateCreated).format('DD.MM.yyyy')
            }
        },
        {
            headerName: "Из счета",
            field: "fromAccountName",
            flex: 1,
            resizable: true,
            filter: "agTextColumnFilter", /*floatingFilter: true,*/
        },
        {
            headerName: "В счет",
            field: "toAccountName",
            flex: 1,
            resizable: true,
            filter: "agTextColumnFilter", /*floatingFilter: true,*/
        },
        // {
        //     headerName: "Тип",
        //     field: "expenseTypeName",
        //     resizable: true,
        //
        //     filter: "agTextColumnFilter", /*floatingFilter: true,*/
        // },


        // {
        //     headerName: "Филиал",
        //     field: "branchName",
        //     flex: 1,
        //     filter: "agTextColumnFilter"
        // },
        {
            headerName: "Сумма",
            hide: false,
            minWidth: 200,
            maxWidth: 200,
            filter: false,
            cellRenderer: function (params: any) {
                return params.data.amount + " " + params.data.currencyName;
            }
        },
        {
            headerName: "Филиал",
            field: "branchName",
            minWidth: 100,
            maxWidth: 100,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Москва', 'Ташкент', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул']
            }
        },
        {
            headerName: "Описание",
            field: "description",
            flex: 1,
            filter: false,
        },
        {
            headerName: "...",
            filter: false,
            field: "price",
            minWidth: checkRole('Administrator') ? 80 : 70,
            maxWidth: checkRole('Administrator') ? 80 : 70,
            cellRenderer: (params: any) =>
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className={'btn btn-sm'} onClick={() => editButton(params)}><EditIcon
                        color="green" size={10}/></button>
                    {checkRole('Administrator') || checkRole('Manager') &&
                        <button type="button" className={'btn btn-sm'} onClick={() => openModal(params)}><DeleteIcon
                            color="red" size={10}/></button>
                    }
                </div>
        },
    ]);

    return (
        <>
            <div
                className="ag-theme-balham"
                style={{height: '100%'}}
            >
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowSelection={'multiple'}
                    pagination={true}
                    paginationPageSize={20}
                    enableCellTextSelection={true}
                    rowModelType={"serverSide"}
                    onGridReady={onGridReady}
                    onFilterChanged={onFilterChanged}
                    rowBuffer={10000}
                    cacheBlockSize={20}
                    enableRangeSelection={true}
                    maxBlocksInCache={2}
                    gridOptions={gridOptions}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    paginationNumberFormatter={paginationNumberFormatter}
                    suppressMenuHide
                    defaultColDef={{filter: true, floatingFilter: true, sortable: true}}
                />
            </div>
        </>
    );
}
