import React from "react";
import TabPage from "../tabs/TabPage";
import CurrencyExchangeTable from "./CurrencyExchangeTable";





export default function CurrencyExchangeTableWrapper() {
    return (
        <TabPage childrenClassName="p-2">
            <CurrencyExchangeTable/>
        </TabPage>
    );
}
