import { useCallback, useEffect, useState } from "react";
import { useInocomesApiContext } from "../../api/incomes/IncomesApiContext";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import IncomesTable from "./IncomesTable";
import TabPage from "../tabs/TabPage";
import Button from "../button/Button";
import DeleteIcon from "../icons/DeleteIcon";
import Pagination from "../pagination/Pagination";
import Modal from "../modal/Modal";
import YesOrNoModal from "../app/YesOrNoModal";
import GetApies, { GetApiesResponse } from "../../api/incomes/CustomApies";

interface IncomesTableWrapperProps {
    readonly create: () => void;
    readonly selectRow: (value: any) => void;
  }

export default function IncomesTableWrapper({
    create,
    selectRow,
}:IncomesTableWrapperProps){

    const { IncomesApi } = useInocomesApiContext();

    const [data, setData] = useState<any>({});
    const [ids, setIds] = useState([]);
    const [isDelModal, setIsDelModal] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [tableLoading, setTableLoading] = useState(false);

    const pageSize = Number(searchParams.get("pageSize") || 25);
    const pageCount = Number(searchParams.get("pageCount") || 1); 

    const { tab = "" } = useParams();

    const api: GetApiesResponse = GetApies(tab);

    useEffect(() => {
      setTableLoading(true);
      IncomesApi.getListIncomesPagination({ pageNumber: pageCount, pageSize: pageSize, api: api.GetPagn })
        .then((response: any) => {
          setData(response);
          setTableLoading(false);
        })
        .catch((error) => {
          toast.error(error.message);
          setTableLoading(false);
        });
    }, [IncomesApi, pageCount, pageSize, api.GetPagn]);

    const deletePost = useCallback((id: any[]) => {
      IncomesApi.deleteIncome({ id: id, api: api.Delete })
        .then((response) => {
          toast.success(response.message);
          setIsDelModal(false);
          window.location.reload();
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }, [ IncomesApi, setIsDelModal, api.Delete]);

    return (
        <TabPage
          childrenClassName="p-2"
        >
          <IncomesTable
            selectRowCheckbox={setIds}
            selectRow={selectRow}
            data={data.items}
            loading={tableLoading}
          />
          <Modal
            width="500px"
            show={isDelModal}
            closeHandler={() => setIsDelModal(false)}
            className="d-flex justify-content-center align-items-center"
          >
            <YesOrNoModal
              titleText="Вы уверены, что хотите удалить?"
              onClick={(value: boolean) => {
                if (value) {
                  deletePost(ids);
                } else {
                  setIsDelModal(false);
                }
              }}
            />
          </Modal>
        </TabPage>
      );
}