import React, {useState, useCallback, useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import TabPage from "../tabs/TabPage";
import UserManagerForm from "./UserManagerForm";
import {useUserApiContext} from "../../api/user/UserApiContext";
import {useRoleApiContext} from "../../api/role/RoleApiContext";
import {useBranchApiContext} from "../../api/branch/BranchApiContext";
import ModalLoader from "../modal/MdalLoader";

interface UserManagerFormWrapperProps {
    readonly back?: () => void;
}

const routeType: any = {
    Manager: "managers",
    Staff: "staff",
    Customer: "customers",
    Courier: "couriers",
    Administrator: "customers",
};

export default function UserManagerFormWrapper({
                                                   back,
                                               }: UserManagerFormWrapperProps) {
    const {UserApi} = useUserApiContext();
    const {RoleApi} = useRoleApiContext();
    const [loading, setLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const [roles, setRoles] = useState<any>([]);
    const [branches, setBranches] = useState<any>([]);
    const navigate = useNavigate();
    const {BranchApi} = useBranchApiContext();

    useEffect(() => {
        BranchApi.getList()
            .then((response: any) => {
                response.branches.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setBranches((user: any) => [...user, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setBranches, BranchApi]);


    useEffect(() => {
        RoleApi.getRoles()
            .then((response: any) => {
                response.roles.forEach((item: any) => {
                    const newRole = {
                        label: item.name,
                        value: item.id,
                    };
                    setRoles((role: any) => [...role, newRole]);
                });
            })
            .catch((err: any) => console.log(err));
    }, [RoleApi, setRoles]);

    const [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        phoneNumber: "",
        branchId: 0,
        address: "",
        roleName: {
            label: "",
            value: "",
        },
        passwordHash: "",
        claim: "",
    });

    const submit = useCallback(
        (value: any) => {
            setLoading(true);
            const data = {
                ...value,
                roleName: [value.roleName.label],
                branchId: value.branchId ? Number(value.branchId.value) : 0,
                phoneNumber: value.phoneNumber.startsWith('+') ? value.phoneNumber : `+${value.phoneNumber}`,
            };
            // console.log(data.roleName[0].length)
            if (data.roleName[0]) {
                UserApi.createUser(data)
                    .then((response) => {
                        setLoading(false);
                        toast.success(response.message);
                        navigate(`/app/users/${routeType[data.roleName[0]]}`);
                    })
                    .catch((error) => {
                        setLoading(false);
                        toast.error(error.message)
                    });
            } else if (data.roleName[0].length === 0) {
                data.roleName[0] = "Customer";
                data.branchId = 0;
                UserApi.createUser(data)
                    .then((response) => {
                        setLoading(false);
                        toast.success(response.message);
                        navigate(`/app/users/${routeType[data.roleName[0]]}`);
                    })
                    .catch((error) => {
                            setLoading(false);
                            toast.error(error.message)
                        }
                    );
                // toast.error("Role is Required!");
            }
        },
        [UserApi, setSearchParams, back]
    );

    return (
        <TabPage childrenClassName="p-3 pt-4">
            <UserManagerForm
                initialValues={initialValues}
                roles={roles}
                branches={branches}
                setInitialValues={setInitialValues}
                submit={submit}
            />
            <ModalLoader open={loading}/>
        </TabPage>
    );
}
