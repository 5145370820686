import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import TabPage from "../tabs/TabPage";
import { update } from "immupdate";
import CurrencyForm from "./CurrencyForm";
import { useCurrencyApiContext } from "../../api/currency/CurrencyApiContext";
import { useParcelApiContext } from "../../api/parcel/ParcelApiContext";
import Button from "../button/Button";
import {useBranchApiContext} from "../../api/branch/BranchApiContext";

export default function CurrencyFormWrapper() {
  const { CurrencyApi } = useCurrencyApiContext();
  const { ParcelApi } = useParcelApiContext();
  const { BranchApi } = useBranchApiContext();
  const [search, setSearch] = useSearchParams();
  const [currencyTypes, setCurrencyTypes] = useState<any[]>([]);
  const [branches, setBranches] = useState<any>([]);

  const navigate = useNavigate();

  const id = useMemo(() => search.get("currencyHistoryId"), [search]);

  const [initialValues, setInitialValues] = useState({
    currencyTypeId: "",
    amount: "",
  });

  useEffect(() => {
    if (id) {
      CurrencyApi.getDetails(id)
        .then((response: any) => {
          setInitialValues((prev: any) =>
            update(prev, {
              currencyTypeId: {
                label: response.currencyName,
                value: response.currencyId,
              },
              rate: response.rate,
              branchId: {
                label: response.branchName,
                value: response.branchId,
              },
            })
          );
        })
        .catch((err: any) => toast.error(err.message));
    }
  }, [id, setInitialValues, CurrencyApi]);

    useEffect(() => {
        CurrencyApi.getCurrencyTypeList()
            .then((response: any) => setCurrencyTypes(response.currencies))
            .catch((error) => console.log(error.message));
    }, [CurrencyApi]);


    useEffect(() => {
        BranchApi.getList()
            .then((response: any) => {
                response.branches.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setBranches((user: any) => [...user, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setBranches, BranchApi]);


  const submit = useCallback(
    (value: any) => {
      if (id) {
        const data = {
          id: id,
          ...value,
          currencyId: value.currencyTypeId.value,
          branchId: value.branchId.value,
        };
        CurrencyApi.update(data)
          .then((response) => {
            toast.success(response.message);
            navigate("/app/currency/table");
          })
          .catch((error) => toast.error(error.message));
      } else {
        const data = {
          ...value,
          currencyId: value.currencyTypeId.value,
          branchId: value.branchId ? value.branchId.value : 0,
        };
        CurrencyApi.create(data)
          .then((response) => {
            toast.success(response.message);
            navigate("/app/currency/table");
          })
          .catch((error) => toast.error(error.message));
      }
    },
    [id, CurrencyApi, navigate]
  );

  return (
    <TabPage
      childrenClassName="p-3 pt-4"
      headerComponent={
        <div className="d-flex align-items-center h-100">
          <Button
            className="bg-gold px-2 py-1 text-light"
            onClick={() => navigate("/app/currency/table")}
          >
            Назад
          </Button>
        </div>
      }
    >
      <CurrencyForm
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        currencyTypes={currencyTypes}
        branches={branches}
        submit={submit}
      />
    </TabPage>
  );
}
