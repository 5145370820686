import React, {useCallback, useState} from 'react';
import {Form, Formik} from 'formik';
import {update} from 'immupdate';

import GroupBox from '../app/GroupBox';
import Button from '../button/Button';
import SelectPickerField from '../form/SelectPickerField';
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";
import DatePicker from "react-datepicker";
import {useCurrencyApiContext} from "../../api/currency/CurrencyApiContext";
import {convertCurrency} from "./currencyConverter";
import {NumericFormat} from 'react-number-format';
import {RotateCcw} from 'lucide-react';

interface FormProps {
    readonly initialValues: any;
    readonly setInitialValues: (value: any) => void;
    readonly submit: (value: any) => void;
    readonly accounts: any[];
    readonly currencies: any[];


}

export default function CurrencyExchangeForm({
                                                 initialValues,
                                                 setInitialValues,
                                                 submit,
                                                 accounts,
                                                 currencies,
                                             }: FormProps) {


    const profile: any = useShallowEqualSelector(profileSelector);

    const [currencyRate, setCurrencyRate] = useState(0);
    const [currencyId, setCurrencyId] = useState();

    const checkRole = useCallback(
        (role: string) => {
            const hasRole = profile.role.filter((ro: string) => ro === role);

            return hasRole.length > 0 ? true : false;
        },
        [profile]
    );

    const onChangeFromCurrency = useCallback((value: any) => {
        setInitialValues((prev: any) => {
            const newConvertedAmount = convertCurrency(value.label, prev.toCurrencyId.label, prev.baseAmount, prev.currencyRate);
            return update(prev, {
                fromCurrencyId: {
                    label: value.label,
                    value: value.value
                },
                convertedAmount: newConvertedAmount,
            });
        });
    }, [setInitialValues]);

    const onChangeToCurrency = useCallback((value: any) => {
        setInitialValues((prev: any) => {
            const newConvertedAmount = convertCurrency(prev.fromCurrencyId.label, value.label, prev.baseAmount, prev.currencyRate);
            return update(prev, {
                toCurrencyId: {
                    label: value.label,
                    value: value.value
                },
                convertedAmount: newConvertedAmount,
            });
        });
    }, [setInitialValues]);

    const onChangeBaseAmount = useCallback(
        (value: any) => {
            const newBaseAmount = value.target.value;
            setInitialValues((prev: any) => {
                const newConvertedAmount = convertCurrency(prev.fromCurrencyId.label, prev.toCurrencyId.label, newBaseAmount, prev.currencyRate);
                return update(prev, {
                    baseAmount: newBaseAmount,
                    convertedAmount: newConvertedAmount,
                });
            });
        },
        [setInitialValues]
    );

    const onChangeConvertedAmount = useCallback(
        (value: any) => {
            const newConvertedAmount = parseFloat(value.target.value);
            setInitialValues((prev: any) => {
                const newCurrencyRate = newConvertedAmount / prev.baseAmount;
                return update(prev, {
                    convertedAmount: newConvertedAmount,
                  //  currencyRate: newCurrencyRate,
                });
            });
        },
        [setInitialValues]
    );

    const onChangeCurrencyRate = useCallback(
        (value: any) => {
            const newCurrencyRate = parseFloat(value.target.value);
            setCurrencyRate(newCurrencyRate); // Update the currencyRate state
            setInitialValues((prev: any) => {
                const convertedAmount = convertCurrency(prev.fromCurrencyId.label, prev.toCurrencyId.label, prev.baseAmount, newCurrencyRate);
                // console.log(prev.fromCurrencyId.label, prev.toCurrencyId.label, prev.baseAmount, newCurrencyRate);
                // console.log(convertedAmount);
                return update(prev, {
                    currencyRate: newCurrencyRate,
                    convertedAmount: convertedAmount,
                });
            });
        },
        [setInitialValues, setCurrencyRate]
    );

    // const convertedAmount = convertCurrency(initialValues.fromCurrencyId, initialValues.toCurrencyId, initialValues.baseAmount, initialValues.currencyRate);

    const onChangeFromAccountId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                fromAccountId: {
                    label: value.label,
                    value: value.value
                }
            })
        );
    }, [setInitialValues]);

    const onChangeToAccountId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                toAccountId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeDate = useCallback((date: Date) => {
        setInitialValues((prev: any) =>
            update(prev, {
                dateCreated: date
            })
        )
    }, [setInitialValues]);





    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={() => submit(initialValues)}
        >
            {() => (
                <Form>
                    <div className="row mt-3">
                        <div className="col-12">
                            <GroupBox title="Конвертация денег (Создание)">
                                <div className="row">
                                    <div className="col-3 mt-2">
                                        <label htmlFor="fromAccountId">Из счета</label>
                                        <SelectPickerField
                                            name="fromAccountId"
                                            id="fromAccountId"
                                            options={accounts}
                                            onChanges={(value) => onChangeFromAccountId(value)}
                                            label=""/>
                                    </div>
                                    <div className="col-3 mt-2">
                                        <label htmlFor="toAccountId">В счет</label>
                                        <SelectPickerField
                                            name="toAccountId"
                                            id="toAccountId"
                                            options={accounts}
                                            onChanges={(value) => onChangeToAccountId(value)}
                                            label=""/>
                                    </div>
                                    <div className="col-3 mt-2">
                                        <label htmlFor="toDate" className="w-100">Дата</label>
                                        <DatePicker
                                            className={"form-control"}
                                            calendarClassName="w-100"
                                            showIcon
                                            dateFormat="dd.MM.yyyy"
                                            selected={initialValues.dateCreated}
                                            onChange={(date: any) => onChangeDate(date)}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-2 mt-2">
                                        <label htmlFor="fromCurrencyId">С валюты</label>
                                        <SelectPickerField
                                            name="fromCurrencyId"
                                            id="fromCurrencyId"
                                            options={currencies}
                                            value={initialValues.fromCurrencyId}
                                            onChanges={(value) => onChangeFromCurrency(value)}
                                            label=""/>
                                    </div>
                                    <div className="col-1 mt-4 d-flex align-items-center justify-content-center">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                setInitialValues((prev: any) => {
                                                    const newFromCurrencyId = prev.toCurrencyId;
                                                    const newToCurrencyId = prev.fromCurrencyId;
                                                    const newConvertedAmount = convertCurrency(newFromCurrencyId.label, newToCurrencyId.label, prev.baseAmount, prev.currencyRate);
                                                    return update(prev, {
                                                        fromCurrencyId: newFromCurrencyId,
                                                        toCurrencyId: newToCurrencyId,
                                                        convertedAmount: newConvertedAmount,
                                                    });
                                                });
                                            }}
                                        >
                                            <RotateCcw />
                                        </button>
                                    </div>
                                    <div className="col-2 mt-2">
                                        <label htmlFor="currencyId">На валюту</label>
                                        <SelectPickerField
                                            name="toCurrencyId"
                                            id="toCurrencyId"
                                            options={currencies}
                                            value={initialValues.toCurrencyId}
                                            onChanges={(value) => onChangeToCurrency(value)}
                                            label=""/>
                                    </div>
                                    <div className="col-2 mt-2">
                                        {/*<InputField*/}
                                        {/*    label="Сумма"*/}
                                        {/*    name="baseAmount"*/}
                                        {/*    value={initialValues.baseAmount}*/}
                                        {/*    onChange={(event: any) =>*/}
                                        {/*        onChangeBaseAmount(event)*/}
                                        {/*    }*/}
                                        {/*/>*/}

                                        <label>Сумма</label>
                                        <NumericFormat
                                            value={initialValues.baseAmount}
                                            prefix=""
                                            onValueChange={(values) => {
                                                onChangeBaseAmount({target: {value: values.value}});
                                            }}
                                            name="baseAmount"
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            className="form-control mt-2"
                                        />

                                    </div>
                                    <div className="col-2 mt-2">

                                        {/*<InputField*/}
                                        {/*    label="Курс валюты"*/}
                                        {/*    name="currencyRate"*/}
                                        {/*    value={currencyRate || 0}*/}
                                        {/*    onChange={(event: any) =>*/}
                                        {/*        onChangeCurrencyRate(event)*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        <label> Курс валюты</label>
                                        <NumericFormat
                                            value={initialValues.currencyRate}
                                            prefix=""
                                            onValueChange={(values) => {
                                                onChangeCurrencyRate({target: {value: values.value}});
                                            }}
                                            name="currencyRate"
                                            decimalScale={1}
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            className="form-control mt-2"
                                        />
                                    </div>

                                    <div className="col-3 mt-2">
                                        {/*<InputField*/}
                                        {/*    label="Конечная сумма"*/}
                                        {/*    name="convertedAmount"*/}
                                        {/*    value={initialValues.convertedAmount}*/}
                                        {/*    onChange={(event: any) =>*/}
                                        {/*        onChangeConvertedAmount(event)*/}
                                        {/*    }*/}
                                        {/*/>*/}

                                        <label>Конечная сумма</label>
                                        <NumericFormat
                                            value={initialValues.convertedAmount || 0}
                                            prefix=""
                                            onValueChange={(values) => {
                                                onChangeConvertedAmount({target: {value: values.value}});
                                            }}
                                            name="convertedAmount"
                                            decimalScale={3}
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            className="form-control mt-2"
                                        />

                                    </div>

                                </div>

                            </GroupBox>
                        </div>
                        <div className="col-12 mt-3">
                            <Button
                                type="submit"
                                className="text-light bg-gold px-2 py-1"
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
