import {useState} from "react";
import "../report/assets/parcel-view-image-list.scss";
import {API_HOST_SECOND} from "../../constants/AppUrls";

interface Props {
    readonly images: any[];
    readonly dateOnly: string;
    readonly setImage: (value: any) => void;
}

export default function ParcelViewImageList({
                                                images,
                                                setImage,
                                                dateOnly
                                            }: Props) {

    const [activeImageIndex, setActiveImageIndex] = useState(0)

    if (images?.length === 0) return null;

    return (
        <div className="parcel-view-image-list row p-4">
            {images && images.map((image: any, index: number) => {
                return (
                    <div
                        key={index}
                        className={`col-2 m-1 image-item ${activeImageIndex === index ? "active-image-item" : ""}`}
                        onClick={() => {
                            setActiveImageIndex(index);
                            setImage(index);
                        }}
                    >
                        {/*<img src={image.imageBytes} width="100%" alt="" />*/}
                        {/*    <img src={`${API_HOST_SECOND}/images/${image.dateCreated}/${image.imageName}`} width="100%" alt=""/>*/}

                        <img src={`${API_HOST_SECOND}/images/${dateOnly}/${image.imageName}`} width="100%" alt=""/>

                    </div>
                )
            })}
        </div>
    )
}