import {useCallback, useEffect, useState} from "react"
import {Form, Formik} from "formik";
import {ExportExpensesProps, ExportParcelProps} from "../../api/parcel/ParcelDto"
import {object} from "yup";
import GroupBox from "../app/GroupBox";
import SelectPickerField from "../form/SelectPickerField";
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css';
import {update} from "immupdate";
import Button from "../button/Button";

interface ExportParcelFormProps {
    readonly initialValues: ExportExpensesProps;
    readonly setInitialValues: (value: any) => void;
    readonly onSubmit: (value: any) => void;
    readonly branches: any[];
    readonly expenses: any[];
    readonly userRole: String;

}

export interface SelectType {
    label: string;
    value: string;
}

const validationSchema = object({})

export default function ExportExpenseForm({
                                             initialValues,
                                             setInitialValues,
                                             onSubmit,
                                             branches,
                                              expenses,
                                              userRole
                                         }: ExportParcelFormProps) {

    const [toBranches, setToBranches] = useState<any[]>(branches);

    useEffect(() => {
        setToBranches(branches)
    }, [branches])

    const onChangeExpenseTypeId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                expenseTypeId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeBranchId = useCallback((value: any) => {
        const _toBranches = branches.filter((d) => d.value !== value.value);
        setToBranches(_toBranches)
        setInitialValues((prev: any) =>
            update(prev, {
                branchId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues, branches]);

    const onChangeToDate = useCallback((date: Date) => {
        setInitialValues((prev: any) =>
            update(prev, {
                toDate: date
            })
        )
    }, [setInitialValues]);

    const onChangeFromDate = useCallback((date: Date) => {
        setInitialValues((prev: any) =>
            update(prev, {
                fromDate: date
            })
        )
    }, [setInitialValues]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={() => onSubmit(initialValues)}
            enableReinitialize={true}
            validationSchema={validationSchema}>
            {() => (
                <Form>
                    <div className="row p-3 mt-3">
                        <div className="col-6">
                            <GroupBox title="По затратам">
                                <div className="row mt-2">

                                    {/*<div className="col-12 mt-2">*/}
                                    {/*    <label htmlFor="expenseTypeId">Тип затраты</label>*/}
                                    {/*    <SelectPickerField*/}
                                    {/*        name="expenseTypeId"*/}
                                    {/*        id="expenseTypeId"*/}
                                    {/*        // options={plans}*/}
                                    {/*        onChanges={(value) => onChangeExpenseTypeId(value)}*/}
                                    {/*        label=""/>*/}
                                    {/*</div>*/}

                                    {userRole === "Administrator" && (
                                        <div className="col-12 mt-2">
                                            <label htmlFor="branchId">Филиал</label>
                                            <SelectPickerField
                                                name="branchId"
                                                id="branchId"
                                                options={branches}
                                                onChanges={(value) => onChangeBranchId(value)}
                                            />
                                        </div>
                                    )}

                                    <div className="col-6 mt-2">
                                        <label htmlFor="toDate" className="w-100">Дата (от)</label>
                                        <DatePicker
                                            className={"form-control"}
                                            calendarClassName="w-100"
                                            showIcon
                                            dateFormat="dd.MM.yyyy"
                                            selected={initialValues.fromDate}
                                            onChange={(date: any) => onChangeFromDate(date)}
                                        />
                                    </div>

                                    <div className="col-6 mt-2 ">
                                        <label htmlFor="toDate" className="w-100">Дата (до)</label>
                                        <DatePicker
                                            className={"form-control"}
                                            calendarClassName="w-100"
                                            showIcon
                                            dateFormat="dd.MM.yyyy"
                                            selected={initialValues.toDate}
                                            onChange={(date: any) => onChangeToDate(date)}
                                        />
                                    </div>
                                </div>
                            </GroupBox>
                        </div>
                        <div className="col-12 mt-3">
                            <Button
                                className="bg-black text-light px-3 py-1"
                                type="submit">
                                Скачать отчет
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}