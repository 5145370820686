import {useNavigate, useSearchParams} from "react-router-dom";
import {useCallback, useEffect, useMemo, useState} from "react";
import {CreateIssuedInvoiceDto} from "../../api/issued-invoice/IssuedInvoiceDto";
import {useMerchantApiContext} from "../../api/merchant/MerchantApiContext";
import {toast} from "react-toastify";
import {useParcelApiContext} from "../../api/parcel/ParcelApiContext";
import {useIssuedInvoiceApiContext} from "../../api/issued-invoice/IssuedInvoiceContext";
import {update} from "immupdate";

import TabPage from "../tabs/TabPage";
import IssuedInvoiceForm from "./IssuedInvoiceForm";
import Button from "../button/Button";
import {useBranchApiContext} from "../../api/branch/BranchApiContext";

export default function IssuedInvoiceFormWrapper() {
    const [initialValues, setInitialValues] = useState<CreateIssuedInvoiceDto>({
        merchantId: 0,
        branchId: 0,
        parcelId: 0,
    });

    const [branches, setBranches] = useState<any[]>([]);
    const [merchants, setMerchants] = useState([]);
    const [parcles, setParcels] = useState([]);
    const [search, setSearch] = useSearchParams();

    const issuedId = useMemo(() => search.get("issuedId") || "", [search]);
    const {BranchApi} = useBranchApiContext();
    const navigate = useNavigate();

    const {IssuedInvoiceApi} = useIssuedInvoiceApiContext();
    const {MerchantApi} = useMerchantApiContext();
    const {ParcelApi} = useParcelApiContext();


    useEffect(() => {
        BranchApi.getList()
            .then((response: any) => {
                response.branches.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setBranches((user: any) => [...user, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setBranches, BranchApi]);


    useEffect(() => {
        if (Boolean(issuedId)) {
            IssuedInvoiceApi.getOneIssuedInvoice({id: Number(issuedId)})
                .then((response: any) => {
                    setInitialValues((prev: any) =>
                        update(prev, {
                            branchId: {
                                label: response.branchName,
                                value: response.branchId,
                            },
                            merchantId: {
                                label: response.merchant.name,
                                value: response.merchant.id,
                            },
                            parcelId: {
                                label: response.parcelCode,
                                value: response.parcelId,
                            },
                        })
                    );
                })
                .catch((error) => console.log(error));
        }
    }, [IssuedInvoiceApi]);

    const getMerchantsBySearching = useCallback(
        (value: string) => {
            if (value !== "") {
                MerchantApi.getMerchantListWithSearch(value)
                    .then((response: any) => {
                        let array: any = [];
                        // eslint-disable-next-line array-callback-return
                        response.customers.map((item: any) => {
                            const data = {
                                label: `${item.tin} ${item.name}`,
                                value: item.id,
                            };
                            array.push(data);
                        });
                        setMerchants(array);
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            }
        },
        [setMerchants, MerchantApi]
    );

    const getParcelsBySearching = useCallback(
        (value: string) => {
            if (value !== "") {
                ParcelApi.getParcelListByCode(value)
                    .then((response: any) => {
                        let array: any = [];
                        // eslint-disable-next-line array-callback-return
                        response.items.map((item: any) => {
                            const data = {
                                label: `${item.code} ${item.recipient.fullName} ${item.recipient.phoneNumber}`,
                                value: item.id,
                            };
                            array.push(data);
                        });
                        setParcels(array);
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            }
        },
        [setMerchants, MerchantApi]
    );

    const onSubmit = useCallback(
        (value: any) => {
            if (Boolean(issuedId)) {
                const data = {
                    branchId: value.branchId.value,
                    parcelId: value.parcelId.value,
                    merchantId: value.merchantId.value,
                    id: Number(issuedId),
                };
                IssuedInvoiceApi.updateIssuedInvoice(data)
                    .then((response) => {
                        toast.success(response.message);
                        navigate("/app/issued-invoice/table");
                    })
                    .catch((error) => console.log(error));
            } else {
                const data = {
                    branchId: value.branchId.value,
                    parcelId: value.parcelId.value,
                    merchantId: value.merchantId.value,
                };
                IssuedInvoiceApi.createIssuedInvoice(data)
                    .then((response) => {
                        toast.success(response.message);
                        navigate("/app/issued-invoice/table");
                    })
                    .catch((error) => console.log(error));
            }
        },
        [IssuedInvoiceApi, issuedId]
    );

    return (
        <TabPage
            childrenClassName="p-3 pt-4"
            headerComponent={
                <div className="d-flex h-100 align-items-center">
                    <Button
                        onClick={() => navigate("/app/issued-invoice/table")}
                        className="text-light px-2 py-1 bg-gold"
                    >
                        Назад
                    </Button>
                </div>
            }
        >
            <IssuedInvoiceForm
                branches={branches}
                merchants={merchants}
                parcels={parcles}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                searchMerchant={getMerchantsBySearching}
                searchParcel={getParcelsBySearching}
                submit={onSubmit}
            />
        </TabPage>
    );
}
