import {useCallback} from "react";
import { Form, Formik } from "formik";
import { object } from "yup";
import { update } from "immupdate";

import GroupBox from "../app/GroupBox";
import Button from "../button/Button";
import SelectPickerField from "../form/SelectPickerField";

interface UserManagerFormProps{
    readonly roles: any,
    readonly initialValues: any;
    readonly setInitialValues: (value: any) => void;
    readonly submit: (value: any) => void;
}

const validationSchema = object({
    // roleName: string().required("Required!"),
})

export default function UserManagerEditRoleForm({
    roles,
    initialValues, 
    setInitialValues, 
    submit
}:UserManagerFormProps){

    const onChangeRoleName = useCallback((value: any)=>{
        setInitialValues((prev: any)=>update(prev, {
            userRoles: value.target.value
        }))
    },[setInitialValues]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={submit}
            >
            {()=>(
                <Form>
                    <div className="row">
                        <div className="col-12">
                            <GroupBox>
                                    <div className="row">
                                        <div className="col-12">
                                        <SelectPickerField
                                            label="Роль"
                                            name="userRole"
                                            options={roles}
                                            className="w-100"
                                            onChange={(event: any)=>onChangeRoleName(event)}
                                            isDisabled={true}
                                         />
                                        </div>
                                    </div>
                            </GroupBox>
                        </div>
                        <div className="col-12 mt-3 d-flex justify-content-end">
                            <Button type="submit" className="text-light bg-gold px-2 py-1">
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}