import React from "react";
import TabPage from "../tabs/TabPage";
import AccountHistoryByIdTable from "./AccountHistoryByIdTable";
import { useLocation } from "react-router-dom";


export default function AccountHistoryByIdTableWrapper() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id') || '';

    return (
        <TabPage childrenClassName="p-2">
            <AccountHistoryByIdTable id={id} />
        </TabPage>
    );
}
