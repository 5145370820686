import React, {useState, useCallback, useEffect, useMemo} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useParcelApiContext} from "../../api/parcel/ParcelApiContext";
import {useInocomesApiContext} from "../../api/incomes/IncomesApiContext";
import {useAccountApiContext} from "../../api/account/AccountApiContext";

import {useAccountHistoryApiContext} from "../../api/accounthistory/AccountHistoryApiContext";
import GetApies, {GetApiesResponse} from "../../api/incomes/CustomApies";

import TabPage from "../tabs/TabPage";
import AccountHistoryForm from "./AccountHistoryForm";
import {update} from "immupdate";
import Button from "../button/Button";
import moment from "moment-timezone";

export default function AccountHistoryFormWrapper() {
    const {ParcelApi} = useParcelApiContext();
    const {IncomesApi} = useInocomesApiContext();
    const {AccountApi} = useAccountApiContext();
    const {AccountHistoryApi} = useAccountHistoryApiContext();
    const [couriers, setCouriers] = useState<any>([]);

    const [search, setSearch] = useSearchParams();
    const [senders, setSenders] = useState<any[]>([]);
    const [branches, setBranches] = useState<any>([]);
    const [selectedSender, setSelectedSender] = useState();
    const [salaryTypes, setSalaryTypes] = useState<any>([]);

    const location = useLocation();
    const navigate = useNavigate();

    const type = (location: string) => {
        if (location.includes("salary")) return "salary";
        else if (location.includes("incomes")) return "incomes";
        else if (location.includes("expense")) return "expenses";
    };

    const type2 = (location: string) => {
        if (location.includes("salary")) return "salary";
        else if (location.includes("incomes")) return "incomes";
        else if (location.includes("expense")) return "expense";
    };

    const type3 = (location: string) => {
        if (location.includes("salary")) return "salary";
        else if (location.includes("income")) return "income";
        else if (location.includes("expense")) return "expense";
    };

    const tab = type(location.pathname) || "salary";

    const path = type3(location.pathname) + "Id";

    const id = useMemo(() => search.get(path), [search, path]);

    const [initialValues, setInitialValues] = useState({
        userId: "",
        accountId: "",
        salaryTypeId: "",
        amount: "",
        description: "",
        dateCreatedFor: new Date(),
        selectedSender: ""
    });

    ///GET
    useEffect(() => {
        if (id) {
            AccountHistoryApi.getDetails(id)
                .then((response: any) => {
                    var date = new Date(response.dateCreatedFor);

                    setInitialValues((prev: any) =>
                        update(prev, {
                            selectedSender: {
                                label: response.userName,
                                value: response.userId,
                            },
                            userId: {
                                label: response.userName,
                                value: response.userId,
                            },
                            accountId: {
                                label:
                                    response.accountName +
                                    " " +
                                    response.accountBalance +
                                    " Sum",
                                value: response.accountId,
                            },
                            salaryTypeId: {
                                label: response.expenseTypeName
                                    ? response.expenseTypeName
                                    : response.incomeTypeName
                                        ? response.incomeTypeName
                                        : response.salaryTypeName
                                            ? response.salaryTypeName
                                            : "",
                                value: response.expenseTypeId
                                    ? response.expenseTypeId
                                    : response.incomeTypeId
                                        ? response.incomeTypeId
                                        : response.salaryTypeId
                                            ? response.salaryTypeId
                                            : "",
                            },
                            amount: response.amount,
                            description: response.description,
                            dateCreatedFor: date
                        })
                    );
                })
                .catch((err: any) => toast.error(err.message));
                // .catch((err: any) => toast.error("line 115"));
        }
    }, [id, setInitialValues, AccountApi, AccountHistoryApi]);

    const api: GetApiesResponse = GetApies(tab);

    useEffect(() => {
        IncomesApi.getListIncomes({api: api.GetList})
            .then((response: any) => {
                // eslint-disable-next-line array-callback-return
                if (response.salariesTypeList) {
                    response.salariesTypeList.map((item: any) => {
                        const data = {
                            label: item.name,
                            value: item.id,
                        };
                        setSalaryTypes((user: any) => [...user, data]);
                    });
                } else if (response.expensesTypeList) {
                    response.expensesTypeList.map((item: any) => {
                        const data = {
                            label: item.name,
                            value: item.id,
                        };
                        setSalaryTypes((user: any) => [...user, data]);
                    });
                } else if (response.incomesTypeList) {
                    response.incomesTypeList.map((item: any) => {
                        const data = {
                            label: item.name,
                            value: item.id,
                        };
                        setSalaryTypes((user: any) => [...user, data]);
                    });
                }
            })
            .catch((err: any) => toast.error(err.message));
            // .catch((err: any) => toast.error("Line152"));
    }, [setInitialValues, IncomesApi, api.GetList]);

    useEffect(() => {
        ParcelApi.getRoles(34)
            .then((response: any) => {
                // eslint-disable-next-line array-callback-return
                response.customers.map((item: any) => {
                    const data = {
                        label: `${item.firstName} ${item.lastName} ${item.phone}`,
                        value: item.id,
                    };
                    setCouriers((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
            // .catch((error) => toast.error("Line 168"));
    }, [setCouriers, ParcelApi]);
    // setSalaryTypes(response.salariesTypeList)


    useEffect(() => {
        AccountApi.getAccountList()
            .then((response: any) => {
                // eslint-disable-next-line array-callback-return
                response.accounts.map((item: any) => {
                    const data = {
                        label: `${item.name} ${item.balance} ${item.currencyCode}`,
                        value: item.id,
                    };
                    setBranches((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
            // .catch((error) => toast.error("Line 186"));
    }, [setBranches, AccountApi]);

    const getSendersBySearching = useCallback(
        (value: string) => {
            if (value !== "") {
                ParcelApi.getSearchUserWithSkip(value)
                    .then((response: any) => {
                        let array: any = [];
                        // eslint-disable-next-line array-callback-return
                        response.customers.map((item: any) => {
                            const data = {
                                label: `${item.firstName} ${item.lastName} ${item.phone}`,
                                value: item.id,
                            };
                            array.push(data);
                        });
                        setSenders(array);
                    })
                    .catch((error) => {
                        toast.error(error.message);
                        // toast.error("Line 207");
                    });
            }
        },
        [setSenders, ParcelApi]
    );

    //SUBMIT button
    const submit = useCallback(
        (value: any) => {
            if (id) {
                const data = {
                    id: id,
                    userId: value.userId.value,
                    accountId: value.accountId.value,
                    salaryTypeId: tab === "salary" ? value.salaryTypeId.value : null,
                    expenseTypeId: tab === "expenses" ? value.salaryTypeId.value : null,
                    incomeTypeId: tab === "incomes" ? value.salaryTypeId.value : null,
                    amount: value.amount,
                    description: value.description,
                    dateCreatedFor: moment(value.dateCreatedFor).format("YYYY-MM-DD"),
                };
                AccountHistoryApi.update(data)
                    .then((response) => {
                        // toast.success(response.message);
                        toast.success("Line 232");
                        navigate(`/app/${type2(location.pathname)}` + "/table");
                    })
                    .catch((error) => toast.error(error.message));
                    // .catch((error) => toast.error("Line 236"));
            } else {

                const data = {
                    userId: value.userId.value,
                    accountId: value.accountId.value,
                    salaryTypeId: tab === "salary" ? value.salaryTypeId.value : null,
                    expenseTypeId: tab === "expenses" ? value.salaryTypeId.value : null,
                    incomeTypeId: tab === "incomes" ? value.salaryTypeId.value : null,
                    amount: value.amount,
                    description: value.description,
                    dateCreatedFor: moment(value.dateCreatedFor).format("YYYY-MM-DD"),
                };
                AccountHistoryApi.create(data)
                    .then((response) => {
                        // toast.success(response.message);
                        toast.success("Line 252");
                        navigate(`/app/${type2(location.pathname)}` + "/table");
                    })
                    .catch((error) => toast.error(error.message));
                    // .catch((error) => toast.error("Line 256"));
            }
        },
        [id, tab, AccountHistoryApi, navigate, location.pathname]
    );

    return (
        <TabPage
            childrenClassName="p-3 pt-4"
            headerComponent={
                <div className="d-flex justify-content-between align-items-center h-100">
                    <Button
                        className="bg-gold text-light px-2 py-1"
                        onClick={() =>
                            navigate(`/app/${type2(location.pathname)}` + "/table")
                        }
                    >
                        Назад
                    </Button>
                </div>
            }
        >
            <AccountHistoryForm
                formName={tab === "salary" ? "Зарплата" : tab === "incomes" ? "Приход" : "Расход"}
                selectedSender={selectedSender}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                searchSender={(value: string) => getSendersBySearching(value)}
                branches={branches}
                senders={couriers}
                salaryTypes={salaryTypes}
                submit={submit}
            />
        </TabPage>
    );
}
