import { useMemo } from "react";
import { MoveMoneyApi } from "./MoveMoneyApi";
import { useApiBase } from "../ApiContext";
import {useDispatch} from "react-redux";

interface Props {
    readonly MoveMoneyApi: MoveMoneyApi;
}

export function useMoveMoneyApiContext(): Props {
    const data = useApiBase();
    const dispatch = useDispatch();
    const api = useMemo(() => new MoveMoneyApi({...data, dispatch}), [data, dispatch]);

    return {
        MoveMoneyApi: api,
    };
}
