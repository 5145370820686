import {BaseApi} from "../BaseApi";
import {GetAccountListPagnQuery} from "./CurrencyDto";

export class CurrencyApi extends BaseApi {
    public getCurrencyHistoryListPagn({
                                          pageNumber,
                                          pageSize,
                                      }: GetAccountListPagnQuery) {
        return this.get(`CurrencyHistory`, {
            query: {pageNumber, pageSize},
        });
    }

    public getCurrencyTypeListPagn({
                                       pageNumber,
                                       pageSize,
                                   }: GetAccountListPagnQuery) {
        return this.get(`CurrencyType/List`, {
            query: {pageNumber, pageSize},
        });
    }

    public getDetails(id: any) {
        return this.get(`CurrencyHistory/${id}`);
    }

    public getCurrencyTypeList() {
        return this.get(`CurrencyType`);
    }

    public getCurrencyTypeListByBranch() {
        return this.get(`CurrencyType/GetCurrencyTypeListByBranch`);
    }

    public create(json: any) {
        return this.post("CurrencyHistory", {json});
    }

    public update(json: any) {
        return this.put("CurrencyHistory", {json});
    }

    public remove(id: any) {
        return this.delete(`CurrencyHistory/${id}`);
    }

    public getCurrencyExchangeListPagn(json: any) {
        return this.post("CurrencyExchange/list", {json});


    }public createCurrencyExchange(json: any) {
        return this.post("CurrencyExchange", {json});
    }

    public updateCurrencyExchange(json: any) {
        return this.put("CurrencyExchange", {json});
    }

    public deleteCurrencyExchange(id: any) {
        return this.delete(`CurrencyExchange/${id}`);
    }

    public getCurrencyExchangeById(id: any) {
        return this.get(`CurrencyExchange/${id}`);
    }

}
