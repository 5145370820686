import {BaseApi} from "../BaseApi";
import {GetAllRole} from "./StatusDto";

export class StatusApi extends BaseApi {
    public getAllStatus({pageNumber, pageSize}: GetAllRole): Promise<any> {
        return this.get(
            `Status/List?pageNumber=${pageNumber}&pageSize=${pageSize}`
        );
    }

    public getDetails(id: any) {
        return this.get(`Status/${id}`);
    }

    public update(json: any) {
        return this.put("Status", {json});
    }

    public create(json: any) {
        return this.post("Status", {json});
    }
}
