import {useMemo} from "react";
import {StatusItemApi} from "./StatusItemApi";
import {useApiBase} from "../ApiContext";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props {
    readonly StatusItemApi: StatusItemApi;
}

export function useStatusItemApiContext(): Props {
    const data = useApiBase();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const api = useMemo(() => new StatusItemApi({...data, navigate, dispatch}), []);

    return {
        StatusItemApi: api
    }
}