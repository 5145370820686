import React, {useCallback} from 'react';
import {Form, Formik} from 'formik';
import {update} from 'immupdate';

import GroupBox from '../app/GroupBox';
import InputField from '../form/InputField';
import Button from '../button/Button';
import SelectPickerField from '../form/SelectPickerField';
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";
import DatePicker from "react-datepicker";
import {NumericFormat} from "react-number-format";

interface StatusFormProps {
    readonly initialValues: any;
    readonly setInitialValues: (value: any) => void;
    readonly submit: (value: any) => void;
    readonly accounts: any[];
}

export default function MoveMoneyForm({
                                          initialValues,
                                          setInitialValues,
                                          submit,
                                          accounts
                                      }: StatusFormProps) {


    const profile: any = useShallowEqualSelector(profileSelector);

    const checkRole = useCallback(
        (role: string) => {
            const hasRole = profile.role.filter((ro: string) => ro === role);

            return hasRole.length > 0 ? true : false;
        },
        [profile]
    );

    const onChangeAmount = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    amount: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeFromAccountId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                fromAccountId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeToAccountId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                toAccountId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);


    const onChangeDate = useCallback((date: Date) => {
        setInitialValues((prev: any) =>
            update(prev, {
                dateCreated: date
            })
        )
    }, [setInitialValues]);

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={() => submit(initialValues)}
        >
            {() => (
                <Form>
                    <div className="row mt-3">
                        <div className="col-12">
                            <GroupBox title="Перемещение денег (Создание)">
                                <div className="row">
                                    <div className="col-6 mt-2">
                                        <label htmlFor="fromAccountId">Из счета</label>
                                        <SelectPickerField
                                            name="fromAccountId"
                                            id="fromAccountId"
                                            options={accounts}
                                            onChanges={(value) => onChangeFromAccountId(value)}
                                            label=""/>
                                    </div>

                                    <div className="col-6 mt-2">
                                        <label htmlFor="toAccountId">В счет</label>
                                        <SelectPickerField
                                            name="toAccountId"
                                            id="toAccountId"
                                            options={accounts}
                                            onChanges={(value) => onChangeToAccountId(value)}
                                            label=""/>
                                    </div>


                                    <div className="col-3">
                                        {/*<InputField*/}
                                        {/*    label="Сумма"*/}
                                        {/*    name="amount"*/}
                                        {/*    value={initialValues.amount}*/}
                                        {/*    onChange={(event: any) =>*/}
                                        {/*        onChangeAmount(event)*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        <label>Сумма</label>
                                        <NumericFormat
                                            value={initialValues.amount}
                                            prefix=""
                                            onValueChange={(values) => {
                                                onChangeAmount({target: {value: values.value}});
                                            }}
                                            name="amount"
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            className="form-control mt-2"
                                        />
                                    </div>

                                    <div className="col-3 mt-2">
                                        <label htmlFor="toDate" className="w-100">Дата (до)</label>
                                        <DatePicker
                                            className={"form-control"}
                                            calendarClassName="w-100"
                                            showIcon
                                            dateFormat="dd.MM.yyyy"
                                            selected={initialValues.dateCreated}
                                            onChange={(date: any) => onChangeDate(date)}
                                        />
                                    </div>
                                </div>
                            </GroupBox>
                        </div>
                        <div className="col-12 mt-3">
                            <Button
                                type="submit"
                                className="text-light bg-gold px-2 py-1"
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
