import {toast} from "react-toastify";
import {useSearchParams} from "react-router-dom";
import {useUserApiContext} from "../../api/user/UserApiContext";
import {useRoleApiContext} from "../../api/role/RoleApiContext";
import React, {useState, useCallback, useEffect, useMemo} from "react";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";
import {CheckUserRole} from "../../utils/CheckUserInfo";
import {update} from "immupdate";

import Button from "../button/Button";
import TabPage from "../tabs/TabPage";
import UserManagerEditClaimForm from "./UserManagerEditClaim";
import UserManagerEditForm from "./UserManagerEditForm";
import UserManagerEditPasswordForm from "./UserManagerEditPassword";
import UserManagerEditRoleForm from "./UserManagerEditRole";
import {useBranchApiContext} from "../../api/branch/BranchApiContext";
import ModalLoader from "../modal/MdalLoader";

interface UserManagerFormWrapperProps {
    readonly back: () => void;
}

export default function UserManagerEditFormWrapper({
                                                       back,
                                                   }: UserManagerFormWrapperProps) {
    const {UserApi} = useUserApiContext();
    const {RoleApi} = useRoleApiContext();
    const [loading, setLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const [roles, setRoles] = useState<any>([]);
    const [responseRoles, setResponseRoles] = useState<any>({});
    const [userClaims, setUserClaims] = useState<any>({});
    const id = searchParams.get("userId");
    const {BranchApi} = useBranchApiContext();

    const [branches, setBranches] = useState<any>([]);
    const [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        phoneNumber: "",
        address: "",
        userBranch: "",
    });

    const [initialPassword, setInitialPassword] = useState({
        passwordHash: "",
    });

    const [initialRole, setInitialRole] = useState({
        userRole: "",
    });


    const [initialClaim, setInitialClaim] = useState({
        userClaims: "",
    });

    const profile: any = useShallowEqualSelector(profileSelector);

    const isAdminOrManager = useMemo(
        () =>
            CheckUserRole(profile?.role, "Administrator") ||
            CheckUserRole(profile?.role, "Manager"),
        [profile]
    );

    useEffect(() => {
        BranchApi.getList()
            .then((response: any) => {
                response.branches.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setBranches((user: any) => [...user, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setBranches, BranchApi]);

    useEffect(() => {
        if (id) {
            setLoading(true)
            UserApi.getUserById(id)
                .then((response: any) => {
                    setLoading(false)

                    setInitialRole((prev: any) =>
                        update(prev, {
                            userRole: {
                                label: response.userRole[0].roleName,
                                value: response.userRole[0].id,
                            }
                        })
                    );
                    setInitialValues(response);
                    setInitialValues((prev: any) =>
                        update(prev, {
                            branchId: {
                                label: response.userBranch ? response.userBranch.name : "",
                                value: response.userBranch ? response.userBranch.id : "",
                            }
                        })
                    );
                    setUserClaims(response.userClaim);
                })
                .catch((error) => {
                    setLoading(false)
                    toast.error(error.message);
                });
        }
    }, [setInitialValues, UserApi, id]);

    useEffect(() => {
        RoleApi.getRoles()
            .then((response: any) => setResponseRoles(response))
            .catch((error) => toast.error(error.message));
    }, [RoleApi]);

    useEffect(() => {
        if (Object.keys(responseRoles).length > 0) {
            const roles = responseRoles.roles;
            for (let i = 0; i < roles.length; i++) {
                const option = {
                    label: roles[i].name,
                    value: roles[i].id,
                };
                setRoles((r: any) => [...r, option]);
            }
        }
    }, [responseRoles, setRoles]);

    useEffect(() => {
        if (userClaims.length > 0) {
            const claims: any = userClaims;
            const data: any = [];
            for (let i = 0; i < claims.length; i++) {
                const option = {
                    label: claims[i].claimType,
                    value: claims[i].claimType,
                };
                data.push(option);
            }
            setInitialClaim((prev: any) =>
                update(prev, {
                    userClaims: data,
                })
            );
        }
    }, [userClaims]);

    const editUserInfo = useCallback(
        (value: any) => {
            setLoading(true);
            const data = {
                id: id,
                firstName: value.firstName,
                lastName: value.lastName,
                address: value.address,
                phoneNumber: value.phoneNumber.startsWith('+') ? value.phoneNumber : `+${value.phoneNumber}`,
                branchId: Number(value.branchId.value),
            };
            UserApi.updateUser(data)
                .then((response) => {
                    setLoading(false);
                    toast.success(response.message);
                    setSearchParams({pageType: "table"});
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error(error.message);
                });
        },
        [UserApi, setSearchParams, id]
    );

    const editUserPassword = useCallback(
        (value: any) => {
            setLoading(true);
            const userId = Number(id);
            const json = {
                passwordHash: value.passwordHash,
                userId: userId,
            };
            UserApi.updateUserPassword(json)
                .then((response) => {
                    setLoading(false);
                    toast.success(response.message);
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error(error.message);
                });
        },
        [id, UserApi]
    );

    const editUserRole = useCallback(
        (value: any) => {
            setLoading(true);

            const userId = Number(id);
            const roleIds: any = [Number(value.userRole.value)];

            const json = {
                userId: userId,
                roleId: roleIds,
            };
            UserApi.addToRole(json)
                .then((response) => {
                    setLoading(false);
                    toast.success(response[0].message);
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error(error[0].message);
                });
        },
        [id, UserApi]
    );

    const editUserClaim = useCallback(
        (value: any) => {
            setLoading(true);

            const claims: any = [];
            value.userClaims.map((cla: any) => {
                claims.push({
                    claimName: cla.label,
                });
            });
            const phone = initialValues.phoneNumber;
            UserApi.addToClaims({
                phoneNumber: phone,
                claimList: claims,
            })
                .then((response) => {
                    setLoading(false);

                    toast.success(response.message);
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error(error.message);
                });
        },
        [UserApi, initialValues.phoneNumber]
    );

    return (
        <TabPage
            childrenClassName="p-3 pt-4"
            headerComponent={
                <Button onClick={back} className="bg-gold text-light mb-2 px-2 py-1">
                    Назад
                </Button>
            }
        >
            <div className="row">
                <div className="col-8 mb-3">
                    <UserManagerEditForm
                        initialValues={initialValues}
                        branches={branches}
                        setInitialValues={setInitialValues}
                        submit={editUserInfo}
                    />
                </div>
                {isAdminOrManager && (
                    <div className="col-4">
                        <UserManagerEditPasswordForm
                            initialValues={initialPassword}
                            setInitialValues={setInitialPassword}
                            submit={editUserPassword}
                        />
                    </div>
                )}
                {isAdminOrManager && (
                    <>
                        <div className="col-12 mt-4">
                            <UserManagerEditRoleForm
                                roles={roles}
                                initialValues={initialRole}
                                setInitialValues={setInitialRole}
                                submit={editUserRole}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <UserManagerEditClaimForm
                                initialValues={initialClaim}
                                setInitialValues={setInitialClaim}
                                submit={editUserClaim}
                            />
                        </div>
                    </>
                )}
            </div>
            <ModalLoader open={loading}/>
        </TabPage>
    );
}
