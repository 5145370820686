import React from "react";
import TabPage from "../tabs/TabPage";
import MoveMoneyTable from "./MoveMoneyTable";




export default function MoveMoneyTableWrapper() {
    return (
        <TabPage childrenClassName="p-2">
            <MoveMoneyTable/>
        </TabPage>
    );
}
