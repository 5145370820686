import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function convertCurrency(
    fromCurrency: string,
    toCurrency: string,
    baseAmount: number,
    currencyRate: number
): number {

    // if (!fromCurrency || !toCurrency || baseAmount <= 0 || currencyRate <= 0) {
    //     toast.error("Invalid input values for currency conversion");
    //     return 0;
    // }

    switch (fromCurrency) {
        case "USD":
            switch (toCurrency) {
                case "UZS":
                case "TRY":
                case "KZT":
                case "KGS":
                case "TJS":
                case "RUB":
                    // console.log(baseAmount * currencyRate);
                    return baseAmount * currencyRate;
                default:
                    //toast.error(`Unsupported target currency: ${toCurrency}`);
                    return 0;
            }
        case "UZS":
        case "TRY":
        case "KZT":
        case "KGS":
        case "TJS":
        case "RUB":
            switch (toCurrency) {
                case "USD":
                    // console.log(baseAmount / currencyRate);
                    return baseAmount / currencyRate;
                default:
                    //toast.error(`Unsupported target currency: ${toCurrency}`);
                    return 0;
            }
        default:
            //toast.error(`Unsupported base currency: ${fromCurrency}`);
            return 0;
    }
}