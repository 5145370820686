import {useParams} from "react-router-dom";
import ContainerLayout from "../components/app/ContainerLayout";
import AccountHistoryByIdTableWrapper from "../components/acounthistroybyid/AccountHistoryByIdTableWrapper";


export default function AccountHistoryByIdContainer(){
    const { tab = "by-id" } = useParams();
    return (
        <ContainerLayout>
            {tab === "by-id" && (
                <AccountHistoryByIdTableWrapper
                />
            )}

        </ContainerLayout>
    )
}