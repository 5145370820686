import {BaseApi} from "../BaseApi";
import {GetAllParcel, GetAllParcelByUser} from "./ParcelDto";

export class ParcelApi extends BaseApi {

    public getAllParcel({
                            pageNumber,
                            pageSize,
                            code,
                        }: GetAllParcel): Promise<any> {
        let url = `Parcel/GetParcelListWithPaginationByCode?pageNumber=${pageNumber}&pageSize=${pageSize}`;

        if (code !== "") {
            url += `&Code=${code}`;
            return this.get(url);
        }
        else {
            return this.get(
                url
                // `Parcel/GetParcelListWithPaginationByCode?pageNumber=${pageNumber}&pageSize=${pageSize}&Code=${code}&fromBranchId=${code}`
            );
        }

    }

    public getAllParcelCustom(json: any) {
        return this.post("Parcel/GetParcelListWithPaginationByCodeCustom", {json});
    }


    // public getParcelById({id}: IdProps): Promise<any> {
    //     return this.get(`Parcel/GetParcelDetailsById/${id}`);
    // }

    public getAllParcelByCourier({
                                     pageNumber,
                                     pageSize,
                                     userId,
                                 }: GetAllParcelByUser): Promise<any> {
        return this.get(
            `Parcel/GetParcelListWithPaginationByCourier?pageNumber=${pageNumber}&pageSize=${pageSize}&userId=${userId}`
        );
    }

    public getAllParcelByCourierPickup({
                                           pageNumber,
                                           pageSize,
                                           userId,
                                       }: GetAllParcelByUser): Promise<any> {
        return this.get(
            `Parcel/GetParcelListWithPaginationByCourierPickup?pageNumber=${pageNumber}&pageSize=${pageSize}&userId=${userId}`
        );
    }

    public getAllParcelByCustomerSending({
                                             pageNumber,
                                             pageSize,
                                             userId,
                                         }: GetAllParcelByUser): Promise<any> {
        return this.get(
            `Parcel/GetParcelListWithPaginationByCustomerSending?pageNumber=${pageNumber}&pageSize=${pageSize}&userId=${userId}`
        );
    }

    public getAllParcelByCustomerReceive({
                                             pageNumber,
                                             pageSize,
                                             userId,
                                         }: GetAllParcelByUser): Promise<any> {
        return this.get(
            `Parcel/GetParcelListWithPaginationByCustomerReceive?pageNumber=${pageNumber}&pageSize=${pageSize}&userId=${userId}`
        );
    }


    public getParcelDetailsByCode(code: any) {
        return this.get(`Parcel/GetParcelDetailsByCode/:code`, {
            params: {code},
        });
    }

    public getParcelListByCode(Code: any) {
        return this.get(`Parcel/GetParcelListWithPaginationByCode`, {
            query: {Code, pageNumber: 1, pageSize: 20},
        });
    }

    public getSearchUserWithSkip(value: any) {
        return this.get(
            `UserManager/SearchUserWithSkip?searchText=${value}&Skip=${0}&Top=${30}`
        );
    }

    public getRoles(id: any) {
        return this.get(`UserManager/GetUserList?RoleId=${id}`);
    }

    public getParcelDetailsById(id: any) {
        return this.get(`Parcel/GetParcelDetailsById/:id`, {
            params: {id},
        });
    }

    public getInfoParcel() {
        return this.get(`Parcel/GetInfoParcel`);
    }

    public getPaymentMethodList() {
        return this.get(`PaymentMethod`);
    }

    public deleteParcel(json: any): Promise<any> {
        // console.log("json: " + json)
        return this.post(`Parcel/DeleteParcels`, {json});
    }

    public sendToTelegramById(json: any): Promise<any> {
        return this.post(`Notifications/SendToTelegramById`, {json});
    }

    public updateParcelStatusesByCode(json: any): Promise<any> {
        return this.post(`Parcel/UpdateParcelItemStatusByCode`, {json});
    }

    public updateParcel(json: any) {
        return this.put("Parcel/UpdateParcel", {json});
    }

    public updateParcelCustom(json: any) {
        return this.put("Parcel/UpdateParcelCustom", {json});
    }

    public createParcel(json: any) {
        return this.post("Parcel/CreateParcel", {json});
    }

    // public exportParcels(json: any) {
    //   return this.post("File/ExportParcels", { json });
    // }


    public getJobList(json: any) {
        return this.downloadPdf("File/GetJobList", "JobList", {json});
    }

    // public getInvoices(json: any, fileName: string = "File Name") {
    //   return this.downloadPdf("File/ExportParcels", fileName, {
    //     json,
    //     method: "post",
    //   });
    // }
    public getInvoices(json: any, fileName: string = "Invoices") {
        return this.downloadPdf("File/GetInvoices", fileName, {
            json,
            method: "post",
        });
    }

    public exportParcels(json: any, fileName: string = "Report") {
        return this.downloadWord("File/ExportParcels", fileName, {
            json,
            method: "post",
        });
    }
    public exportExpenses(json: any, fileName: string = "Report") {
        return this.downloadWord("File/ExportExpenses", fileName, {
            json,
            method: "post",
        });
    }

    public getInvoice(json: any, fileName: string = "Invoice") {
        return this.downloadPdf("File/GetHtmlInvoice", fileName, {
            json,
            method: "post",
        });
    }

    public getStickerById({id, code}: any) {
        return this.downloadPdf("File/GetStickers", `${code}`, {
            query: {id},
        });
    }

    public getStickerByCode({id, itemCode}: any) {
        return this.downloadPdf("File/GetSticker", `${itemCode}`, {
            query: {id, itemCode},
        });
    }

    public getSticker(json: any, fileName: string) {
        return this.downloadPdf("File/GetSticker", fileName, {json});
    }
}
