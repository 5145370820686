import { useEffect, useMemo, useState } from "react";
import { useAuthApiContext } from "../../api/auth/AuthApiContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { profileSelector } from "../../reducers/authReducer";
import ReportBranchTableWrapper from "./ReportBranchTableWrapper";
import ReportCards from "./ReportCards";
import { CheckUserRole } from "../../utils/CheckUserInfo";

export default function ReportTab() {
  const { AuthApi } = useAuthApiContext();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accountList, setAccountList] = useState([]);

  const profile: any = useShallowEqualSelector(profileSelector);

  const isAdminOrManager = useMemo(
    () =>
      CheckUserRole(profile?.role, "Administrator") ||
      CheckUserRole(profile?.role, "Manager"),
    [profile]
  );

  // useEffect(() => {
  //   setLoading(true);
  //   AuthApi.getDashboardInfo()
  //     .then((response) => {
  //       setData(response);
  //       setAccountList(response.branchAccountCount);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       //console.log(error);
  //       setLoading(false);
  //     });
  // }, [AuthApi, setData]);
  //
  // if (loading) {
  //   return (
  //     <div className="w-100 h-100 d-flex justify-content-center align-items-center">
  //       <h1>Loading...</h1>
  //     </div>
  //   );
  // }

  return (
    <div className="p-3">
      <ReportCards userRole={profile?.role[0]}/>
      {/*{isAdminOrManager && (*/}
      {/*  <ReportBranchTableWrapper data={accountList} />*/}
      {/*)}*/}
    </div>
  );
}
