import React from "react";
import TabPage from "../tabs/TabPage";
import SalaryHistoryTable from "./SalaryHistoryTable";



export default function SalaryHistoryTableWrapper() {
    return (
        <TabPage childrenClassName="p-2">
            <SalaryHistoryTable/>
        </TabPage>
    );
}
