import React, {useEffect, useState} from "react";
import TableButton from "../button/TableButton";
import DeleteIcon from "../icons/DeleteIcon";
import EditIcon from "../icons/EditIcon";
import Table from "../table/Table";
import {CheckUserRole} from "../../utils/CheckUserInfo";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";
import {DateFormatter} from "../../utils/DateFormatter";

interface BranchTableProps {
    readonly data: any;
    readonly loading: boolean;
    readonly selectRow: (value: any) => void;
    readonly deleteRow: (value: any) => void;
    readonly type?: string;
    readonly typeTable?: string;

}

export default function TransactionMoneyTable({
                                                data,
                                                loading,
                                                selectRow,
                                                deleteRow,
                                                type,
                                                typeTable
                                            }: BranchTableProps) {

    const profile: any = useShallowEqualSelector(profileSelector);

    const [tableHeaders, setTableHeaders] = useState([])

    const headers: any = [
        // {
        //   header: "№",
        //   access: "id",
        //   width: 40,
        // },
        // {
        //   header: "Номер счета",
        //   access: "accountNumber",
        //   width: 200,
        // },
        // {
        //   // header: type + " Type Name",
        //   header: "Тип затраты",
        //   access: type + "TypeName",
        //   width: 200,
        // },
        // {
        //     header: "Наименование счета",
        //     access: "accountName",
        //     width: 200,
        // },
        {
            header: "Пользователь",
            access: "userName",
            width: 200,
        },
        // {
        //     header: "Номер посылки",
        //     access: "parcelCode",
        //     width: 100,
        // },
        {
            header: "Тип затраты",
            access: "expenseTypeName",
            width: 100,
        },
        {
            header: "Стоимость",
            access: "amount",
            width: 80,
        },
        // {
        //     header: "Оплата в местной валюте",
        //     access: "amount",
        //     width: 80,
        // },
        {
            header: "Описание",
            access: "description",
            width: 250,
        },

        {
            header: 'Дата',
            access: 'dateCreated',
            width: 120,
            ceil: (row: any) => {
                return <div style={{textAlign: 'center'}}>{DateFormatter(row.dateCreated)}</div>;
            },
        },
        {
            header: "...",
            access: "edit",
            ceil: (row: any) => {
                if (type === "incomeParcel") return null
                return (
                    <div className="d-flex">
                        <TableButton className="bg-warning" onClick={() => selectRow(row)}>
                            <EditIcon color="white" size={14}/>
                        </TableButton>
                        <TableButton className="bg-danger ms-2" onClick={() => deleteRow(row)}>
                            <DeleteIcon color="white" size={14}/>
                        </TableButton>
                    </div>
                );
            },
            width: 50,
        },
    ];


    return (
        <Table
            loading={loading}
            data={data}
            headers={headers}
        />
    );
}
