import {useParams} from "react-router-dom";

import ContainerLayout from "../components/app/ContainerLayout";
import MoveMoneyFormWrapper from "../components/money/MoveMoneyFormWrapper";
import MoveMoneyTableWrapper from "../components/money/MoveMoneyTableWrapper";
import CurrencyExchangeFormWrapper from "../components/currencyexchange/CurrencyExchangeFormWrapper";
import CurrencyExchangeTableWrapper from "../components/currencyexchange/CurrencyExchangeTableWrapper";

export default function MoneyContainer(){

    const { tab = "branch" } = useParams();

    return (
        <ContainerLayout>
            {tab === "formMoveMoney" && (
                <MoveMoneyFormWrapper/>
            )}
            {tab === "tableMoveMoney" && (
                <MoveMoneyTableWrapper/>
            )}
            {tab === "formConvertMoney" && (
                <CurrencyExchangeFormWrapper/>
            )}
            {tab === "tableConvertMoney" && (
                <CurrencyExchangeTableWrapper/>
            )}
        </ContainerLayout>
    )
}