import "../report/assets/parcel.scss";
import DeleteIcon from "../icons/DeleteIcon";
import { API_HOST_SECOND } from "../../constants/AppUrls";
import React from "react";

interface Props {
    readonly data: any[];
    readonly dateCreated: string;
    readonly deleteImage: (value: number) => void;
}

export default function AddParcelShowImages({
                                                data,
                                                dateCreated,
                                                deleteImage,
                                            }: Props) {
    let imageValue: string;

    return (
        <div className="row">
            {data && data.length > 0 && (
                <>
                    {data.map((item: any, index: number) => {
                        if (item.imageSrc == null) {
                            imageValue = `${API_HOST_SECOND}/images/${dateCreated}/${item.imageName}`;
                        } else {
                            imageValue = item.imageSrc;
                        }

                        return (
                            <div className={`col-2 custom-parcel-image active-custom-parcel-image`} key={index}>
                                <button type="button" className="custom-delete-button" onClick={() => deleteImage(index)}>
                                    <DeleteIcon size={16} />
                                </button>
                                <img width="100%" src={imageValue} />
                            </div>
                        );
                    })}
                </>
            )}
            {data.length == 0 && (
                <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: "0px" }}>
                    {/*<h2>Загрузка изображений</h2>*/}
                </div>
            )}
        </div>
    );
}