import React, {useCallback, useMemo, useRef, useState} from "react";
import {Form, Formik} from "formik";
import {object, string} from "yup";
import {update} from "immupdate";

import GroupBox from "../app/GroupBox";
import InputField from "../form/InputField";
import Button from "../button/Button";
import SelectPickerField from "../form/SelectPickerField";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";
import {CheckUserRole} from "../../utils/CheckUserInfo";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface UserManagerFormProps {
    readonly initialValues: any;
    readonly roles: any;
    readonly branches: any[];
    readonly setInitialValues: (value: any) => void;
    readonly submit: (value: any) => void;
}

const validationSchema = object({
    firstName: string().required("Required!"),
    lastName: string(),
    userName: string(),
    email: string(),
    phoneNumber: string().required("Required!"),
    address: string(),
    passwordHash: string(),
})


export default function UserManagerForm({
                                            initialValues,
                                            roles,
                                            setInitialValues,
                                            submit,
                                            branches,
                                        }: UserManagerFormProps) {

    const inqFormRef = useRef<any>(null);

    const profile: any = useShallowEqualSelector(profileSelector);

    const isAdminOrManager = useMemo(() => (CheckUserRole(profile?.role, "Administrator") || CheckUserRole(profile?.role, "Manager") /*|| CheckUserRole(profile?.role, "Staff")*/), [profile])

    const onChangeFirstName = useCallback((value: any) => {
        setInitialValues((prev: any) => update(prev, {
            firstName: value.target.value
        }))
    }, [setInitialValues])

    const onChangeLastName = useCallback((value: any) => {
        setInitialValues((prev: any) => update(prev, {
            lastName: value.target.value
        }))
    }, [setInitialValues])

    const onChangeAddressCustom = useCallback((value: any) => {
        // console.log(value.value)
        setInitialValues((prev: any) =>
            update(prev, {
                address: value.value,
            })
        );
    }, [setInitialValues]);



    const onChangeEmail = useCallback((value: any) => {
        setInitialValues((prev: any) => update(prev, {
            email: value.target.value
        }))
    }, [setInitialValues])

    const onChangePhoneNumber = useCallback((value: any) => {
        setInitialValues((prev: any) => update(prev, {
            phoneNumber: value.startsWith('+') ? value : `+${value}`
        }))

    }, [setInitialValues])

    const onChangeUserName = useCallback((value: any) => {
        setInitialValues((prev: any) => update(prev, {
            userName: value.target.value
        }))
    }, [setInitialValues])

    const onChangeParcelBranchId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                branchId: {
                    label: value.label,
                    value: value.value,
                },
            })
        );
    }, [setInitialValues, initialValues]);

    const onChangeRoleName = useCallback((value: any) => {
        setInitialValues((prev: any) => update(prev, {
            roleName: value
        }))
    }, [setInitialValues])



    const onChangeAddress = useCallback((value: any) => {
        setInitialValues((prev: any) => update(prev, {
            address: value.target.value
        }))
    }, [setInitialValues])

    const onChangePassword = useCallback((value: any) => {
        setInitialValues((prev: any) => update(prev, {
            passwordHash: value.target.value
        }))
    }, [setInitialValues])

    const inqFormRefHandler = useCallback((instance: any) => {
        if (instance) {
            inqFormRef.current = instance
        }
    }, [inqFormRef])

    const checkRole = useCallback(
        (role: string) => {
            const hasRole = profile.role.filter((ro: string) => ro === role);

            return hasRole.length > 0 ? true : false;
        },
        [profile]
    );
    const [phoneNumber, setPhoneNumber] = useState();
    const [valid, setValid] = useState(false)

    const handleChange = (value: any) => {
        setPhoneNumber(value);
        setValid(validatePhoneNumber(value));
    }

    const validatePhoneNumber = (value: any) => {
        const phoneNumberPattern = /^\d{10}$/;
        return phoneNumberPattern.test(value);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={submit}
            innerRef={inqFormRefHandler}
        >
            {() => (
                <Form>
                    <div className="row">
                        <div className="col-8">
                            <GroupBox title="Инфомрация о пользователя">
                                <div className="row">
                                    <div className="col-6 my-1">
                                        <InputField
                                            label="Имя"
                                            name="firstName"
                                            value={initialValues.firstName}
                                            onChange={(event: any) => onChangeFirstName(event)}
                                        />
                                    </div>
                                    <div className="col-6 my-1">
                                        <InputField
                                            label="Фамилия"
                                            name="lastName"
                                            value={initialValues.lastName}
                                            onChange={(event: any) => onChangeLastName(event)}
                                        />
                                    </div>
                                    {checkRole('Administrator') &&
                                        <div className="col-6 my-1">
                                            <SelectPickerField
                                                name="branchId"
                                                options={branches}
                                                onChanges={(value: any) => onChangeParcelBranchId(value)}
                                                label="Филиал"
                                            />
                                        </div>
                                    }



                                    {/*<div className="col-6 my-1">*/}
                                    {/*<InputField*/}
                                    {/*    label="Имя пользователя (ник)"*/}
                                    {/*    name="userName"*/}
                                    {/*    value={initialValues.userName}*/}
                                    {/*    onChange={(event: any)=>onChangeUserName(event)}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    {/*<div className="col-6 my-1">*/}
                                    {/*    <InputField*/}
                                    {/*        label="Контакт"*/}
                                    {/*        name="phoneNumber"*/}
                                    {/*        value={initialValues.phoneNumber}*/}
                                    {/*        onChange={(event: any) => onChangePhoneNumber(event)}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="col-6 my-1">
                                        <label>
                                            <label style={{marginBottom: '10px'}}> {/* Adjust the margin as needed */}
                                                Номер телефона
                                            </label>
                                            <PhoneInput
                                                prefix={"+"}
                                                inputProps={{
                                                    name: 'phoneNumber',
                                                    required: true,
                                                    autoFocus: true,
                                                    style: {width: '100%'}
                                                }}
                                                onlyCountries={['ru', 'uz', 'kg', 'kz', 'tj', 'tr']}
                                                // country={'uz'}
                                                value={initialValues.phoneNumber}
                                                onChange={onChangePhoneNumber}
                                                defaultErrorMessage={'Неверный номер телефона'}
                                            />
                                        </label>
                                    </div>
                                    <div className="col-12 my-1">
                                        {/*<label htmlFor="floatingInput">Адрес</label>*/}
                                        {/*<ReactDadataBox*/}
                                        {/*    token="113631dffc75281557122c991cbe0c1d28bd1f48" query={initialValues.address}*/}
                                        {/*    allowClear={true}*/}
                                        {/*    placeholder="Введите адрес доставки"*/}
                                        {/*    onChange={suggestion => onChangeAddressCustom(suggestion)}*/}
                                        {/*/>*/}
                                        <InputField
                                            label="Адрес"
                                            name="address"
                                            value={initialValues.address}
                                            onChange={(event: any) => onChangeAddress(event)}
                                        />
                                    </div>
                                </div>
                            </GroupBox>
                        </div>
                        {isAdminOrManager &&
                            <div className="col-4">
                                <GroupBox
                                    title="Дополнительная информация"
                                >
                                    <div className="row">
                                        <div className="col-12 my-1">
                                            <InputField
                                                label="Пароль"
                                                name="passwordHash"
                                                className="w-100"
                                                value={initialValues.passwordHash}
                                                onChange={(event: any) => onChangePassword(event)}
                                            />
                                        </div>
                                        <div className="col-12 my-1">
                                            <SelectPickerField
                                                label="Роль"
                                                name="roleName"
                                                options={roles}
                                                className="w-100"
                                                onChanges={(event: any) => onChangeRoleName(event)}
                                                isDisabled={true}
                                            />
                                        </div>
                                    </div>
                                </GroupBox>
                            </div>
                        }
                        <div className="col-12 mt-3">
                            <Button type="submit" className="text-light bg-gold px-2 py-1">
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}