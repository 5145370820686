import React, {useState, useEffect, useCallback} from "react";
import {toast} from "react-toastify";
import Pagination from "../pagination/Pagination";
import TabPage from "../tabs/TabPage";
import Modal from "../modal/Modal";
import {update} from "immupdate";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import YesOrNoModal from "../app/YesOrNoModal";
import {useParcelApiContext} from "../../api/parcel/ParcelApiContext";
import {Form, Formik} from "formik";
import {object, string} from "yup";
import InputField from "../form/InputField";
import {useAccountApiContext} from "../../api/account/AccountApiContext";
import {useAccountHistoryApiContext} from "../../api/accounthistory/AccountHistoryApiContext";
import AccountHistoryTable from "./AccountHistoryTable";
import Button from "../button/Button";

const validationSchema = object({
    code: string(),
});

export default function AccountHistoryTableWrapper() {
    const {ParcelApi} = useParcelApiContext();
    const {AccountApi} = useAccountApiContext();
    const {AccountHistoryApi} = useAccountHistoryApiContext();

    const location = useLocation();
    const navigate = useNavigate();

    const type = (location: string) => {
        if (location.includes("salary")) return "salary";
        else if (location.includes("income")) return "income";
        else if (location.includes("accounthistory")) return "incomeParcel";
        else if (location.includes("expense")) return "expense";
    };

    const type2 = (location: string) => {
        if (location.includes("salary")) return "salary";
        else if (location.includes("incomes")) return "incomes";
        else if (location.includes("income-parcel")) return "incomeParcel";
        else if (location.includes("expense")) return "expense";
    };

    const [data, setData] = useState<any>({});
    const [ids, setIds] = useState([]);
    const [isDelModal, setIsDelModal] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [tableLoading, setTableLoading] = useState(false);
    const pageSize = Number(searchParams.get("pageSize") || 25);
    const pageCount = Number(searchParams.get("pageCount") || 1);
    const [initialValues, setInitialValues] = useState({
        code: "",
    });

    useEffect(() => {
        setTableLoading(true);
        AccountApi.getAccountListPagn({
            pageNumber: pageCount,
            pageSize: pageSize,
            // listType: type(location.pathname),
        })
            .then((response: any) => {
                setData(response);
                setTableLoading(false);
            })
            .catch((error) => {
                toast.error(error.message);
                setTableLoading(false);
            });
    }, [AccountApi, pageCount, pageSize, location.pathname]);

    const deletePost = useCallback(() => {
        const id = ids;
        ParcelApi.deleteParcel({id})
            .then((response) => {
                toast.success(response.message);
                setIsDelModal(false);
                window.location.reload();
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }, [ids, setIsDelModal, ParcelApi]);

    const onChangeCode = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    code: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onSubmit = useCallback(
        (value: any) => {
            if (value.code.length === 9 || value.code.length === 8) {
                ParcelApi.getAllParcel({
                    pageNumber: pageCount,
                    pageSize: pageSize,
                    code: value.code
                })
                    .then((response: any) => setData(response))
                    .catch((error) => toast.error(error.message));
            } else if (value.code.length === 0) {
                ParcelApi.getAllParcel({
                    pageNumber: pageCount,
                    pageSize: pageSize,
                    code: value.code
                })
                    .then((response: any) => setData(response))
                    .catch((error) => toast.error(error.message));
            } else {
                toast.warning("Введен не полный код");
            }
        },
        [ParcelApi, pageCount, pageSize]
    );

    return (
        <TabPage
            childrenClassName="p-2"
            headerComponent={
                <div className="d-flex justify-content-between align-items-center h-100">
                    {type(location.pathname) !== "incomeParcel" ? (<Button
                        className="bg-gold px-2 py-1 text-light"
                        onClick={() => navigate(`/app/${type2(location.pathname)}/form`)}
                    >
                        Создать
                    </Button>) : (
                        <div/>
                    )}
                    <Formik
                        initialValues={initialValues}
                        onSubmit={() => onSubmit(initialValues)}
                        validationSchema={validationSchema}
                    >
                        {() => (
                            <Form>
                                <InputField
                                    width={300}
                                    name="code"
                                    placeholder="Поиск..."
                                    value={initialValues.code}
                                    onChange={(value: any) => onChangeCode(value)}
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            }
            footerComponent={
                <div className="d-flex justify-content-end my-3">
                    <Pagination
                        pageNumber={data.pageNumber}
                        totalCount={data.totalCount}
                        totalPages={data.totalPages}
                        onSubmit={(value: any) => console.log(value)}
                    />
                </div>
            }
        >
            <AccountHistoryTable
                type={type(location.pathname)}
                typeTable={"income"}
                loading={tableLoading}
                deleteRow={(value: any) => {
                    AccountHistoryApi.remove(value.id)
                        .then((response) => {
                            toast.success(response.message);
                            window.location.reload();
                        })
                        .catch((err) => toast.error(err.message));
                }}
                selectRow={(value) =>
                    navigate(
                        `/app/${type2(location.pathname)}/form?${type(
                            location.pathname
                        )}Id=${value.id}`
                    )
                }
                data={data.items}
            />
            <Modal
                width="500px"
                show={isDelModal}
                closeHandler={() => setIsDelModal(false)}
                className="d-flex justify-content-center align-items-center"
            >
                <YesOrNoModal
                    titleText="Вы уверены, что хотите удалить?"
                    onClick={(value: boolean) => {
                        if (value) {
                            deletePost();
                        } else {
                            setIsDelModal(false);
                        }
                    }}
                />
            </Modal>
        </TabPage>
    );
}
