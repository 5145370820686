import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { update } from "immupdate";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { useCurrencyApiContext } from "../../api/currency/CurrencyApiContext";
import { useParcelApiContext } from "../../api/parcel/ParcelApiContext";
import { noop } from "lodash";
import { useCustomerApiContext } from "../../api/customer/CustomerContext";

import Pagination from "../pagination/Pagination";
import TabPage from "../tabs/TabPage";
import CustomerMySentParcelTable from "./CustomerMySentParcelTable";
import InputField from "../form/InputField";
import Modal from "../modal/Modal";
import GroupBox from "../app/GroupBox";
import SelectPickerField from "../form/SelectPickerField";

export default function CustomerMySentParcelTableWrapper() {
  const [data, setData] = useState<any>({});
  const [tableLoading, setTableLoading] = useState(false);
  const [currencyFormModal, setCurrencyFormModal] = useState<any>({
    modalType: false,
    data: "",
  });

  const [initialValues, setInitialValues] = useState({
    code: "",
  });

  const [searchParams] = useSearchParams();

  const [currencyList, setCurrencyList] = useState([]);

  const pageSize = Number(searchParams.get("pageSize") || 25);
  const pageCount = Number(searchParams.get("pageCount") || 1);

  const { CustomerApi } = useCustomerApiContext();
  const { CurrencyApi } = useCurrencyApiContext();
  const { ParcelApi } = useParcelApiContext();

  useEffect(() => {
    setTableLoading(true);
    CustomerApi.getSendingParcelList({
      pageNumber: pageCount,
      pageSize: pageSize,
      code: Number(initialValues.code),
    })
      .then((response: any) => {
        setData(response);
        setTableLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setTableLoading(false);
      });
  }, [CustomerApi, pageCount, pageSize]);

  const onChangeCode = useCallback(
    (value: any) => {
      if (value.target.value.length <= 9) {
        setInitialValues((prev: any) =>
          update(prev, {
            code: value.target.value,
          })
        );
      }
    },
    [setInitialValues]
  );

  useEffect(() => {
    CurrencyApi.getCurrencyTypeList()
      .then((response) => setCurrencyList(response.currencies))
      .catch((error) => console.log(error));
  }, [CurrencyApi]);

  const onSubmit = useCallback(
    (value: any) => {
      if (value.code.length === 9 || value.code.length === 8) {
        CustomerApi.getSendingParcelList({
          pageNumber: pageCount,
          pageSize: pageSize,
          code: value.code,
        })
          .then((response: any) => setData(response))
          .catch((error) => toast.error(error.message));
      } else {
        toast.warning("Введен не полный код");
      }
    },
    [CustomerApi, pageCount, pageSize]
  );

  const onChangeCurreny = useCallback(
    (value: any) => {
      ParcelApi.getInvoice(
        { id: currencyFormModal?.data?.id, currencyId: value.value },
        currencyFormModal.data.code
      );
      setCurrencyFormModal((prev: any) =>
        update(prev, {
          modalType: false,
        })
      );
    },
    [currencyFormModal, ParcelApi]
  );

  return (
    <TabPage
      headerComponent={
        <div className="d-flex justify-content-between align-items-center h-100">
          <div />
          <Formik
            initialValues={initialValues}
            onSubmit={() => onSubmit(initialValues)}
          >
            {() => (
              <Form>
                <InputField
                  width={300}
                  className="ms-3"
                  name="code"
                  placeholder="Поиск..."
                  value={initialValues.code}
                  onChange={(value: any) => onChangeCode(value)}
                />
              </Form>
            )}
          </Formik>
        </div>
      }
      footerComponent={
        <div className="d-flex justify-content-between my-3">
          <div />
          <Pagination
            pageNumber={data.pageNumber}
            totalCount={data.totalCount}
            totalPages={data.totalPages}
            onSubmit={(value: any) => console.log(value)}
          />
        </div>
      }
    >
      <CustomerMySentParcelTable data={data?.items} loading={tableLoading}  onPrintWithCurrency={(value: any) => {
          setCurrencyFormModal((prev: any) =>
            update(prev, {
              modalType: true,
              data: value,
            })
          );
        }} />
      <Modal
        show={currencyFormModal.modalType}
        closeHandler={() =>
          setCurrencyFormModal((prev: any) =>
            update(prev, { modalType: false })
          )
        }
        className="d-flex justify-content-center align-items-center"
        width="400px"
      >
        <div className="p-3">
          <GroupBox>
            <Formik initialValues={{ currency: "" }} onSubmit={noop}>
              {() => (
                <Form>
                  <SelectPickerField
                    label="Выберите валюты"
                    options={currencyList}
                    onChanges={(event) => onChangeCurreny(event)}
                    name="currency"
                  />
                </Form>
              )}
            </Formik>
          </GroupBox>
        </div>
      </Modal>
    </TabPage>
  );
}
