import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useBranchApiContext } from "../../api/branch/BranchApiContext";

import Button from "../button/Button";
import TabPage from "../tabs/TabPage";
import BranchForm from "./BranchForm";

interface BranchFormWrapperProps {
  readonly back: () => void;
}

export default function BranchFormWrapper({ back }: BranchFormWrapperProps) {
  const { BranchApi } = useBranchApiContext();

  const [search] = useSearchParams();

  const id = useMemo(() => search.get("branchId"), [search]);

  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    city: "",
    country: "",
    phone: "",
    address: "",
    code: "",
  });

  useEffect(() => {
    if (id) {
      BranchApi.getDetails(id)
        .then((response: any) => setInitialValues(response))
        .catch((err: any) => toast.error(err.message));
    }
  }, [setInitialValues, id, BranchApi]);

  const submit = useCallback(
    (value: any) => {
      if (id) {
        const data = {
          ...value,
          id: id,
        };
        BranchApi.update(data)
          .then((response) => {
            toast.success(response.message);
            back()
          })
          .catch((error) => toast.error(error.message));
      } else {
        const data = {
          ...value,
        };
        BranchApi.create(data)
          .then((response) => {
            toast.success(response.message);
            back()
          })
          .catch((error) => toast.error(error.message));
      }
    },
    [BranchApi, id, back]
  );

  return (
    <TabPage
      childrenClassName="p-3 pt-4"
      headerComponent={
        <div className="d-flex align-items-center h-100">
        <Button onClick={back} className="bg-gold text-light mb-2 px-2 py-1">
          Назад
        </Button>
        </div>
      }
    >
      <BranchForm
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        submit={submit}
      />
    </TabPage>
  );
}
