import {ApiProps, BaseApi} from "../BaseApi";
import { GetAccountListPagnQuery } from "./AccountDto";

export class AccountApi extends BaseApi {

    // constructor(apiProps: ApiProps) {
    //     super(apiProps);
    // }

    public getAccountList() {
        return this.get(`Account`);
    }

    public getAccountListPagn({
        pageNumber, 
        pageSize, 
        accountTypeId,
      }: GetAccountListPagnQuery) {
        return this.get(`Account/List`, {
            query: { pageNumber, pageSize, accountTypeId }
        });
    }

    public getAccountDetails(id: any) {
        return this.get(`Account/${id}`);
    }

    public getAccountTypeList() {
        return this.get(`Account/TypeList`);
    }

    public createAccount(json: any) {
        return this.post("Account", { json });
    }

    public updateAccount(json: any) {
        return this.put("Account", { json });
    }

    public deleteAccount(id: any) {
        return this.delete(`Account/${id}`);
    }
}
