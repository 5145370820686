import {BaseApi} from "../BaseApi";
import {
    CreateMerchantBody,
    GetAllWithPaginationQuery,
    UpdateMerchantBody,
} from "./MerchantDto";

export class MerchantApi extends BaseApi {
    public getAllMerchantListWithPagn({
                                          pageNumber,
                                          pageSize,
                                      }: GetAllWithPaginationQuery) {
        return this.get(`Merchant/GetMerchantListWithPagination`, {
            query: {pageNumber, pageSize},
        });
    }

    public getMerchantList(): Promise<any> {
        return this.get(`Merchant/GetMerchantList`);
    }

    public getMerchantListWithSearch(searchText: string) {
        return this.get(`Merchant/SearchMerchantWithSkip`, {
            query: {searchText, skip: 0, top: 20},
        });
    }

    public getDetails(id: any) {
        return this.get(`Merchant/${id}`);
    }

    public crete(json: CreateMerchantBody) {
        return this.post(`Merchant`, {
            json,
        });
    }

    public update(json: UpdateMerchantBody) {
        return this.put(`Merchant`, {
            json,
        });
    }

    public remove(id: number) {
        return this.delete(`Merchant/${id}`);
    }
}
