import {BaseApi} from '../BaseApi';
import {GetAllRole} from './PlanDto';

export class PlanApi extends BaseApi {
    public getAllPlan({pageNumber, pageSize}: GetAllRole): Promise<any> {
        return this.get(
            `Plan/List?pageNumber=${pageNumber}&pageSize=${pageSize}`
        );
    }

    public getDetails(id: any) {
        return this.get(`Plan/${id}`);
    }

    public remove(id: any): Promise<any> {
        return this.delete(`Plan`, id);
    }

    public update(json: any) {
        return this.put('Plan', {json});
    }

    public create(json: any) {
        return this.post('Plan', {json});
    }

    public getPlanList() {
        return this.get(`Plan`);
    }
}
