import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import Pagination from "../pagination/Pagination";
import Button from "../button/Button";
import DeleteIcon from "../icons/DeleteIcon";
import TabPage from "../tabs/TabPage";
import Modal from "../modal/Modal";
import { useSearchParams } from "react-router-dom";
import YesOrNoModal from "../app/YesOrNoModal";
import StatusTable from "./StatusTable";
import { useStatusApiContext } from "../../api/status/StatusApiContext";

interface StatusTableWrapperProps {
  readonly create: () => void;
  readonly selectRow: (value: any) => void;
}

export default function StatusTableWrapper({
  create,
  selectRow,
}: StatusTableWrapperProps) {
  const { StatusApi } = useStatusApiContext();
  const [data, setData] = useState<any>({});
  const [ids, setIds] = useState([]);
  const [isDelModal, setIsDelModal] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tableLoading, setTableLoading] = useState(false);
  const pageSize = Number(searchParams.get("pageSize") || 25);
  const pageCount = Number(searchParams.get("pageCount") || 1);

  useEffect(() => {
    setTableLoading(true);
    StatusApi.getAllStatus({ pageNumber: pageCount, pageSize: pageSize })
    .then((response: any) => {
      setData(response);
      setTableLoading(false);
    })
    .catch((error) => {
      toast.error(error.message);
      setTableLoading(false);
    });
  }, [StatusApi, pageCount, pageSize]);


  return (
    <TabPage
      childrenClassName="p-2"
      headerComponent={
        <div className="d-flex align-items-center h-100">
        <Button
          onClick={() => create()}
          className="text-light px-2 py-1 bg-gold"
        >
          Создать
        </Button>
        </div>
      }
      footerComponent={
          <div className="d-flex justify-content-end my-3">
                  <Pagination
                      pageNumber={data.pageNumber}
                      totalCount={data.totalCount}
                      totalPages={data.totalPages}
                      onSubmit={(value: any) => console.log(value)}
                  />
          </div>
      }
    >
        <StatusTable
            selectRowCheckbox={setIds}
            selectRow={selectRow}
            data={data.items}
            loading={tableLoading}
        />
        <Modal
            width="500px"
            show={isDelModal}
            closeHandler={() => setIsDelModal(false)}
            className="d-flex justify-content-center align-items-center"
      >
        <YesOrNoModal
          titleText="Вы уверены, что хотите удалить?"
          onClick={(value: boolean) => {
            if (value) {

            } else {
              setIsDelModal(false);
            }
          }}
        />
      </Modal>
    </TabPage>
  );
}
