import { useEffect, useState, useCallback } from "react";
import { useStatusItemApiContext }  from "../../api/statusitem/StatusItemApiContext";
import { useUserApiContext } from "../../api/user/UserApiContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useParcelApiContext } from "../../api/parcel/ParcelApiContext";

import TabPage from "../tabs/TabPage";
import EditParcelStatusForm from "./EditParcelStatusForm";
import ModalLoader from "../modal/MdalLoader";

const defaultValues = {
  parcelItemCode: [],
  searchText: "",
  sendSmsToRecipient: false,
  sendSmsToSender: false,
  sendSmsToTelegram: false,
  statusId: "",
  recipientCourierId: "",
};

export default function EditParcelStatusFormWrapper() {
  const [statuses, setStatuses] = useState<any[]>([]);
  const [couriers, setCouriers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    parcelItemCode: [],
    searchText: "",
    sendSmsToRecipient: false,
    sendSmsToSender: false,
    sendSmsToTelegram: false,
    statusId: "",
    recipientCourierId: "",
  });

  const { StatusItemApi } = useStatusItemApiContext();

  const { UserApi } = useUserApiContext();
  const { ParcelApi } = useParcelApiContext();

  useEffect(() => {
      StatusItemApi.getStatusItemList()
      .then((response: any) => {
          const newStatusItem = response.statusItems.filter((item: any)  => item.id !== 1);
          newStatusItem.map((status: any) => {
            const data = {
              label: status.name,
              value: status.id,
            };
            setStatuses((status: any) => [...status, data]);
          });
      })
      .catch((error: any) => console.log(error));
  }, [StatusItemApi, setStatuses]);


    useEffect(() => {
        ParcelApi.getRoles(4)
            .then((response: any) => {
                // eslint-disable-next-line array-callback-return
                response.customers.map((item: any) => {
                    const data = {
                        label: `${item.firstName} ${item.lastName} ${item.phone}`,
                        value: item.id,
                    };
                    setCouriers((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setCouriers, ParcelApi]);

  // useEffect(() => {
  //   UserApi.getAllUsersWithoutPagination(4)
  //     .then((response: any) => {
  //       // eslint-disable-next-line array-callback-return
  //       response.customers &&
  //         response.customers.map((curier: any) => {
  //           const data = {
  //             label:
  //               curier.firstName + " " + curier.lastName + " " + curier.phone,
  //             value: curier.id,
  //           };
  //           setCuriers((curier: any) => [...curier, data]);
  //         });
  //     })
  //     .catch((error: any) => console.log(error));
  // }, [UserApi, setCuriers]);

  const sendStatus = useCallback(
    (value: any) => {
      setLoading(true);
      const array: any = [];
      // eslint-disable-next-line array-callback-return
      value.parcelItemCode &&
        value.parcelItemCode.map((code: any) => {
          array.push(Number(code.title));
        });

      const data = {
        parcelItemStatusId: value.statusId.value,
        recipientCourierId: value.recipientCourierId.value,
        parcelItemCode: array,
        sendSmsToRecipient: value.sendSmsToRecipient,
        sendSmsToSender: value.sendSmsToSender,
        sendSmsToTelegram: value.sendSmsToTelegram,
        isPrint: value.isPrint,
      };
      ParcelApi.updateParcelStatusesByCode(data)
        .then((response: any) => {
          // console.log(response);
          //navigate("/app/parcels");
          toast.success(response.message);
          setInitialValues(defaultValues);

            const data = {
                currencyId: 1,
                code: array,
            };
            if(value.isPrint)
                ParcelApi.getInvoices(data);
            setLoading(false);


        })
        .catch((error: any) => {
          // console.log(error);
          toast.error(error.name);
          setLoading(false);
        });
    },
    [setInitialValues, navigate, ParcelApi]
  );

  return (
    <TabPage childrenClassName="p-4" className="p-4">
      <EditParcelStatusForm
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        statuses={statuses}
        couriers={couriers}
        onSubmit={(value) => sendStatus(value)}
      />
      <ModalLoader open={loading} />
    </TabPage>
  );
}
