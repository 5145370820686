import React, {useCallback, useMemo, useRef, useState} from "react";
import EditIcon from "../icons/EditIcon";
import DeleteIcon from "../icons/DeleteIcon";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";
import {toast} from "react-toastify";
import {PaginationNumberFormatterParams} from "ag-grid-community";
import {AgGridReact} from "ag-grid-react";
import moment from "moment-timezone";
import {useNavigate} from "react-router-dom";
import {useCurrencyApiContext} from "../../api/currency/CurrencyApiContext";

export default function CurrencyTable() {
    const profile: any = useShallowEqualSelector(profileSelector);
    const navigator = useNavigate();
    const {CurrencyApi} = useCurrencyApiContext();

    const checkRole = useCallback(
        (role: string) => {
            const hasRole = profile.role.filter((ro: string) => ro === role);
            return hasRole.length > 0 ? true : false;
        },
        [profile]
    );

    const editButton = (params: any) => {
        navigator(`/app/currency/form?currencyHistoryId=${params.data.id}`)
    }

    const openModal = (params: any) => {
        const userConfirmed = window.confirm("Are you sure you want to delete?");
        if (userConfirmed) {
            deleteCurrencyHistory(params.data.id);
        }
    };

    const deleteCurrencyHistory = useCallback((id: any) => {
        CurrencyApi.remove(id)
            .then((response) => {
                toast.success(response.message);
                window.location.reload();
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }, [CurrencyApi]);

    const datasource = {
        getRows(params: any) {
            const {startRow, endRow, filterModel, sortModel} = params.request
            let count = params.request.endRow - params.request.startRow; // or the cacheBlockSize
            let page = Math.ceil(params.request.endRow / count);

            const filterKeysNumberOfPoint = Object.keys(params.request.filterModel)
            filterKeysNumberOfPoint.forEach(filter => {
                if (filterKeysNumberOfPoint[0] === "numberOfPoint") {
                    // initialValues.numberOfPoint = "";
                    //   initialValues.numberOfPoint = `${filterModel[filter].filter}`
                }
            })
            //
            const filterKeys = Object.keys(filterModel)
            filterKeys.forEach(filter => {
                if (filterKeys[0] === "code") {
                    //   initialValues.code = "";
                    // initialValues.code += `${filterModel[filter].filter}`
                }
            })

            const filterKeysFromDate = Object.keys(filterModel)

            filterKeysFromDate.forEach(filter => {
                if (filterKeys[0] === "dateCreatedString") {
                    if (filterModel[filter].dateFrom !== null) {
                        // initialValues.fromDate = "";
                        // initialValues.fromDate += `${filterModel[filter].dateFrom}`
                    }
                    if (filterModel[filter].dateTo !== null) {
                        // initialValues.toDate = "";
                        // initialValues.toDate += `${filterModel[filter].dateTo}`
                    }
                }
            })

            const filterKeysFromDateStatus = Object.keys(filterModel)
            filterKeysFromDateStatus.forEach(filter => {
                if (filterKeys[0] === "dateCreatedStatus") {
                    if (filterModel[filter].dateFrom !== null) {
                        // initialValues.fromDateStatus = "";
                        // initialValues.fromDateStatus += `${filterModel[filter].dateFrom}`
                    }
                    if (filterModel[filter].dateTo !== null) {
                        // initialValues.toDateStatus = "";
                        // initialValues.toDateStatus += `${filterModel[filter].dateTo}`
                    }
                }
            })

            CurrencyApi.getCurrencyHistoryListPagn({
                pageNumber: page,
                pageSize: count,
                // Add other necessary parameters here
            })
                .then((response: any) => {
                    params.success({
                        rowData: response.items,
                        rowCount: response.items.totalCount,
                    });
                })
                .catch((error: any) => {
                    if (error.message === '') {
                        toast.error('Посылка не найдена!');
                    } else {
                        toast.error(error.message);
                    }
                });
        }
    };

    const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
        return [20, 50, 100, 10000];
    }, []);

    const paginationNumberFormatter = useCallback(
        (params: PaginationNumberFormatterParams) => {
            return "[" + params.value.toLocaleString() + "]";
        },
        [],
    );

    const [gridApi, setGridApi] = useState(null);
    const onGridReady = (params: any) => {
        setGridApi(params);
        params.api.updateGridOptions({serverSideDatasource: datasource})
    }
    const gridOptions = {
        getRowId: (params: any) => {
            return params.data.id;
        },
    };
    const gridRef = useRef<AgGridReact>(null);
    const [columnDefs] = useState([
        {
            headerName: "№",
            field: "id",
            minWidth: 70,
            maxWidth: 70,
        },
        {
            headerName: "Валюта",
            field: "currencyName",
            minWidth: 200,
            maxWidth: 200,
            filter: false,
        },
        {
            headerName: "Курс",
            field: "rate",
            minWidth: 130,
            maxWidth: 130,
            filter: false,
        },
        {
            headerName: "Филиал",
            field: "branchName",
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Москва', 'Ташкент', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул']
            },
            hide: false,
            minWidth: 100,
            maxWidth: 100
        },
        {
            headerName: "Дата",
            minWidth: 90,
            maxWidth: 90,
            cellRenderer: function (row: any) {
                return moment(row.data.dateCreated).format('DD.MM.yyyy')
            }
        },
        {
            headerName: "...",
            field: "price",
            filter: false,
            minWidth: checkRole('Administrator') ? 105 : 80,
            maxWidth: checkRole('Administrator') ? 105 : 80,
            cellRenderer: (params: any) =>
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className={'btn btn-sm'} onClick={() => editButton(params)}><EditIcon
                        color="green" size={10}/></button>
                    {checkRole('Administrator') &&
                        <button type="button" className={'btn btn-sm'} onClick={() => openModal(params)}><DeleteIcon
                            color="red" size={10}/></button>
                    }
                </div>
        },
    ]);

    return (
        <>
            <div
                className="ag-theme-balham"
                style={{height: '100%'}}
            >
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowSelection={'multiple'}
                    pagination={true}
                    paginationPageSize={20}
                    enableCellTextSelection={true}
                    rowModelType={"serverSide"}
                    onGridReady={onGridReady}
                    rowBuffer={10000}
                    cacheBlockSize={20}
                    enableRangeSelection={true}
                    maxBlocksInCache={2}
                    gridOptions={gridOptions}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    paginationNumberFormatter={paginationNumberFormatter}
                    suppressMenuHide
                    defaultColDef={{filter: false, floatingFilter: false, sortable: false}}
                />
            </div>
        </>
    );
}
