import {BaseApi} from "../BaseApi";

export class AccountHistoryApi extends BaseApi {

    public getList(json: any) {
        return this.post("AccountHistory/List", {json});
    }

    public getListById(json: any) {
        return this.post("AccountHistory/ListById", {json});
    }

    public getDetails(id: any) {
        return this.get(`AccountHistory/${id}`);
    }

    public create(json: any) {
        return this.post("AccountHistory", {json});
    }

    public update(json: any) {
        return this.put("AccountHistory", {json});
    }

    public remove(id: any) {
        return this.delete(`AccountHistory/${id}`);
    }
}
