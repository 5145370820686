import {useCallback, useState, useEffect} from "react"
import {Form, Formik} from "formik";
import {ExportParcelProps} from "../../api/parcel/ParcelDto"
import {bool, mixed, object, string} from "yup";
import GroupBox from "../app/GroupBox";
import SelectPickerField from "../form/SelectPickerField";
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css';
import {update} from "immupdate";
import Button from "../button/Button";

interface ExportParcelFormProps {
    readonly initialValues: ExportParcelProps;
    readonly setInitialValues: (value: any) => void;
    readonly onSubmit: (value: any) => void;
    readonly senders: any[];
    readonly recipients: any[];
    readonly plans: any[];
    readonly statuses: any[];
    readonly branches: any[];
    readonly couriers: any[];
    readonly staffs: any[];
    readonly searchSender: (value: string) => void;
    readonly searchRecipient: (value: string) => void;
}

export interface SelectType {
    label: string;
    value: string;
}

const validationSchema = object({})

export default function ExportParcelForm({
                                             initialValues,
                                             setInitialValues,
                                             onSubmit,
                                             recipients,
                                             senders,
                                             branches,
                                             plans,
                                             statuses,
                                             couriers,
                                             staffs,
                                             searchSender,
                                             searchRecipient,
                                         }: ExportParcelFormProps) {

    const [toBranches, setToBranches] = useState<any[]>(branches);

    useEffect(() => {
        setToBranches(branches)
    }, [branches])

    const onChangeSenderId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                senderId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeReceipentId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                recipientId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeSenderCourierId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                senderCourierId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeReceipentCourierId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                recipientCourierId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeSenderStaffId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                senderStaffId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeRecipientStaffId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                recipientStaffId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangePlanId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                planId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeStatus = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                statusId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeFromBranchId = useCallback((value: any) => {
        const _toBranches = branches.filter((d) => d.value !== value.value);
        setToBranches(_toBranches)
        setInitialValues((prev: any) =>
            update(prev, {
                fromBranchId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues, branches]);

    const onChangeToBranchId = useCallback((value: any) => {
        setInitialValues((prev: any) =>
            update(prev, {
                toBranchId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    }, [setInitialValues]);

    const onChangeToDate = useCallback((date: Date) => {
        setInitialValues((prev: any) =>
            update(prev, {
                toDate: date
            })
        )
    }, [setInitialValues]);

    const onChangeStatusDate = useCallback((date: Date) => {
        setInitialValues((prev: any) =>
            update(prev, {
                statusDate: date
            })
        )
    }, [setInitialValues]);

    const onChangeCheckBoxStatusFromDate = useCallback((date: Date) => {
        setInitialValues((prev: any) =>
            update(prev, {
                statusDate: date
            })
        )
    }, [setInitialValues]);

    const onChangeFromDate = useCallback((date: Date) => {
        setInitialValues((prev: any) =>
            update(prev, {
                fromDate: date
            })
        )
    }, [setInitialValues]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={() => onSubmit(initialValues)}
            enableReinitialize={true}
            validationSchema={validationSchema}>
            {() => (
                <Form>
                    <div className="row p-3 mt-3">
                        <div className="col-6">
                            <GroupBox title="По посылкам">
                                <div className="row mt-2">
                                    {/*<div className="col-12">*/}
                                    {/*    <label htmlFor="senderId">ФИО отправителя</label>*/}
                                    {/*    <SelectPickerField*/}
                                    {/*        options={senders}*/}
                                    {/*        onChanges={(value) => onChangeSenderId(value)}*/}
                                    {/*        onInputChange={(value) => searchSender(value)}*/}
                                    {/*        name="senderId"*/}
                                    {/*        id="senderId"*/}
                                    {/*        isSearchable*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 mt-2">*/}
                                    {/*    <label htmlFor="senderCourierId">ФИО курьера (для забора)</label>*/}
                                    {/*    <SelectPickerField*/}
                                    {/*        name="senderCourierId"*/}
                                    {/*        id="senderCourierId"*/}
                                    {/*        options={couriers}*/}
                                    {/*        onChanges={(value) => onChangeSenderCourierId(value)}*/}
                                    {/*        label=""/>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12">*/}
                                    {/*    <label htmlFor="senderStaffId">ФИО регистратора отправителя</label>*/}
                                    {/*    <SelectPickerField*/}
                                    {/*        name="senderStaffId"*/}
                                    {/*        id="senderStaffId"*/}
                                    {/*        options={staffs}*/}
                                    {/*        onChanges={(value) => onChangeSenderStaffId(value)}*/}
                                    {/*        isSearchable*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="col-12 mt-2">
                                        <label htmlFor="planId">Тариф</label>
                                        <SelectPickerField
                                            name="planId"
                                            id="planId"
                                            options={plans}
                                            onChanges={(value) => onChangePlanId(value)}
                                            label=""/>
                                    </div>
                                    <div className="col-6 mt-2">
                                        <label htmlFor="fromBranchId">Откуда (с какого филиала)</label>
                                        <SelectPickerField
                                            name="fromBranchId"
                                            id="fromBranchId"
                                            options={branches}
                                            onChanges={(value) => onChangeFromBranchId(value)}
                                            label=""/>
                                    </div>
                                    <div className="col-6 mt-2">
                                        <label htmlFor="toBranchId">Куда (на какой филиал)</label>
                                        <SelectPickerField
                                            name="toBranchId"
                                            id="toBranchId"
                                            options={toBranches}
                                            onChanges={(value) => onChangeToBranchId(value)}
                                            label=""/>
                                    </div>
                                    <div className="col-3 mt-2">
                                        <label htmlFor="toDate" className="w-100">Дата (от)</label>
                                        <DatePicker
                                            className={"form-control"}
                                            calendarClassName="w-100"
                                            showIcon
                                            dateFormat="dd.MM.yyyy"
                                            selected={initialValues.fromDate}
                                            onChange={(date: any) => onChangeFromDate(date)}
                                        />
                                    </div>
                                    <div className="col-3 mt-2">
                                        <label htmlFor="toDate" className="w-100">Дата (до)</label>
                                        <DatePicker
                                            className={"form-control"}
                                            calendarClassName="w-100"
                                            showIcon
                                            dateFormat="dd.MM.yyyy"
                                            selected={initialValues.toDate}
                                            onChange={(date: any) => onChangeToDate(date)}
                                        />
                                    </div>
                                </div>
                            </GroupBox>
                        </div>
                        {/*<div className="col-6">*/}
                        {/*    <GroupBox title="Получатель">*/}
                        {/*        <div className="row mt-2">*/}
                        {/*            <div className="col-12">*/}
                        {/*                <label htmlFor="recipientId">ФИО получателя</label>*/}
                        {/*                <SelectPickerField*/}
                        {/*                    name="recipientId"*/}
                        {/*                    id="recipientId"*/}
                        {/*                    isSearchable*/}
                        {/*                    options={recipients}*/}
                        {/*                    onChanges={(value) => onChangeReceipentId(value)}*/}
                        {/*                    onInputChange={(value) => searchRecipient(value)}*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*            <div className="col-12 mt-2">*/}
                        {/*                <label htmlFor="recipientCourierId">ФИО курьера (для доставки)</label>*/}
                        {/*                <SelectPickerField*/}
                        {/*                    name="recipientCourierId"*/}
                        {/*                    id="recipientCourierId"*/}
                        {/*                    options={couriers}*/}
                        {/*                    onChanges={(value) => onChangeReceipentCourierId(value)}*/}
                        {/*                    label=""/>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-12">*/}
                        {/*                <label htmlFor="recipientStaffId">ФИО регистратора получателя</label>*/}
                        {/*                <SelectPickerField*/}
                        {/*                    name="recipientStaffId"*/}
                        {/*                    id="recipientStaffId"*/}
                        {/*                    options={staffs}*/}
                        {/*                    onChanges={(value) => onChangeRecipientStaffId(value)}*/}
                        {/*                    isSearchable*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*            <div className="col-4 mt-2">*/}
                        {/*                <label htmlFor="statusId">Статус</label>*/}
                        {/*                <SelectPickerField*/}
                        {/*                    name="statusId"*/}
                        {/*                    id="statusId"*/}
                        {/*                    isSearchable*/}
                        {/*                    options={statuses}*/}
                        {/*                    onChanges={(value) => onChangeStatus(value)}*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*            <div className="col-4 mt-2">*/}
                        {/*                <label htmlFor="statusFromDate" className="w-100 mt-1">Дата статуса (от)</label>*/}
                        {/*                <DatePicker*/}
                        {/*                    showIcon*/}
                        {/*                    dateFormat="dd.MM.yyyy"*/}
                        {/*                    //selected={initialValues.statusDate}*/}
                        {/*                    onChange={(date: any) => onChangeStatusDate(date)}*/}
                        {/*                />*/}

                        {/*            </div>*/}
                        {/*            <div className="col-4 mt-2">*/}
                        {/*                <label htmlFor="statusToDate" className="w-100 mt-1">Дата статуса (до)</label>*/}
                        {/*                <DatePicker*/}
                        {/*                    showIcon*/}
                        {/*                    dateFormat="dd.MM.yyyy"*/}
                        {/*                    //selected={initialValues.statusDate}*/}
                        {/*                    onChange={(date: any) => onChangeStatusDate(date)}*/}
                        {/*                />*/}
                        {/*            </div>*/}


                        {/*        </div>*/}
                        {/*    </GroupBox>*/}
                        {/*</div>*/}
                        <div className="col-12 mt-3">
                            <Button
                                className="bg-black text-light px-3 py-1"
                                type="submit">
                                Скачать отчет
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}