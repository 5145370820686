import React, {useCallback, useEffect, useState} from "react";
import TabPage from "../tabs/TabPage";
import {ExportExpensesProps, ExportParcelProps} from "../../api/parcel/ParcelDto";
import {useParcelApiContext} from "../../api/parcel/ParcelApiContext";
import moment from "moment-timezone";
import {useBranchApiContext} from "../../api/branch/BranchApiContext";
import ModalLoader from "../modal/MdalLoader";
import ExportExpenseForm from "./ExportExpenseForm";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { profileSelector } from "../../reducers/authReducer";

export default function ExportExpensesFormWrapper() {
    const [initialValues, setInitialValues] = useState<ExportExpensesProps>({
        branchId: {
            label: "",
            value: "",
        },
        expenseTypeId: {
            label: "",
            value: "",
        },
        fromDate: new Date(),
        toDate: new Date(),
    });
    const [branches, setBranches] = useState<any>([]);
    const [expenses, setExpenses] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const {ParcelApi} = useParcelApiContext();
    const {BranchApi} = useBranchApiContext();

    const profile: any = useShallowEqualSelector(profileSelector);

    const onSubmit = useCallback((value: any) => {
        const data = {
            expenseTypeId: value.expenseTypeId.value !== "" ? value.expenseTypeId.value : 0,
            branchId: value.branchId.value !== "" ? value.branchId.value : 0,
            fromDate: moment(value.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value.toDate).format("YYYY-MM-DD"),
        };

        setLoading(true);

        ParcelApi.exportExpenses(data)
            .finally(() => {
                setLoading(false);
            });

    }, [ParcelApi]);

    useEffect(() => {
        BranchApi.getList()
            .then((response: any) => {
                response.branches.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setBranches((user: any) => [...user, data]);

                });

            })
            .catch((error) => console.log(error.message));
    }, [setBranches, BranchApi]);

    // useEffect(() => {
    //     BranchApi.getBranchList()
    //         .then((response: any) => {
    //             response.branches.map((item: any) => {
    //                 const data = {
    //                     label: item.name,
    //                     value: item.id,
    //                 };
    //                 setBranches((user: any) => [...user, data]);
    //
    //             });
    //
    //         })
    //         .catch((error) => console.log(error.message));
    // }, [setBranches, BranchApi]);

    return (
        <TabPage childrenClassName="p-2" className="p-4">

            <ExportExpenseForm
                userRole={profile?.role[0]}
                branches={branches}
                expenses={branches}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                onSubmit={onSubmit}
            />

            <ModalLoader open={loading}/>
        </TabPage>

    );
}
