import {BaseApi} from "../BaseApi";
import {GetAllRole} from "./BranchDto";

export class BranchApi extends BaseApi {
    public getLists({pageNumber, pageSize}: GetAllRole): Promise<any> {
        return this.get(
            `Branch/List?pageNumber=${pageNumber}&pageSize=${pageSize}`
        );
    }

    public getList(){
        return this.get(`Branch`);
    }

    public getDetails(id: any) {
        return this.get(`Branch/${id}`);
    }

    public create(json: any) {
        return this.post("Branch", {json});
    }

    public update(json: any) {
        return this.put("Branch", {json});
    }

    public remove(del: any): Promise<any> {
        return this.post(`Branch/DeleteBranches`, del);
    }
}
