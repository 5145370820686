import ReportCard from "./ReportCard";
import {Archive, Truck, Users, User, Landmark, HandCoins} from "lucide-react";
import {RouteContainerTabs} from "../../constants/Routes";


interface Props {
  // readonly data: any;
  readonly userRole: string; // Change String to string
}

export default function ReportCards({ userRole }: Props) {
  return (
      <div className="row">
        {(userRole === "Administrator" || userRole === "Manager" || userRole === "Staff") && (
        <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-3 my-2">
          <ReportCard
              icon={<Archive color={'white'}  />}
              color="olive"
              title="Отчет по посылкам"
              link={RouteContainerTabs.ExportParcel}
              // count={data?.branchCount}
          />
        </div>
        )}
        {(userRole === "Administrator" || userRole === "Manager"  || userRole === "Staff") && (
        <div className="col-xxl-2 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <ReportCard
              icon={<Users color={'white'} />}
              color="red"
              title="Отчет по клиентам"
              link={RouteContainerTabs.ExportParcelByCustomer}
              // count={data?.managerCount?.total}
          />
        </div>
        )}
          {(userRole === "Administrator" || userRole === "Manager"  || userRole === "Staff") && (
              <div className="col-xxl-2 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
                  <ReportCard
                      icon={<Users color={'white'} />}
                      color="blue"
                      title="Отчет по контрагентам"
                      link={RouteContainerTabs.ExportParcelByMerchant}
                      // count={data?.managerCount?.total}
                  />
              </div>
          )}
        {(userRole === "Administrator" || userRole === "Manager"  || userRole === "Staff") && (
        <div className="col-xxl-2 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <ReportCard
              icon={<Truck  color={'white'}/>}
              color="green"
              title="Отчет по курьерам"
              link={RouteContainerTabs.ExportParcelByCourier}
              // count={data?.staffCount?.total}
          />
        </div>
        )}
        {(userRole === "Administrator" || userRole === "Manager") && (
        <div className="col-xxl-2 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <ReportCard
              icon={<User color={'white'} />}
              color="orange"
              title="Отчет по менеджерам"
              link={RouteContainerTabs.ExportParcelByManager}
              // count={data?.courierCount?.total}
          />
        </div>
        )}
        {(userRole === "Administrator" || userRole === "Manager") && (
        <div className="col-xxl-2 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <ReportCard
              icon={<User color={'white'} />}
              color="maroon"
              title="Отчет по регистраторам"
              link={RouteContainerTabs.ExportParcelByStaff}
              // count={data?.customerCount?.total}
          />
        </div>
        )}
        {/*{(userRole === "Administrator" || userRole === "Manager") && (*/}
        {/*<div className="col-xxl-2 col-xl-3 col-lg-6 col-md-6 col-6 my-2">*/}
        {/*  <ReportCard*/}
        {/*      icon={<Landmark color={'white'} />}*/}
        {/*      color="purple"*/}
        {/*      title="Отчет по филиалам"*/}
        {/*      link={RouteContainerTabs.ExportParcelByBranch}*/}
        {/*      // count={data?.parcelCount?.total}*/}
        {/*      // fromMe={data?.parcelCount?.fromMe}*/}
        {/*      // toMe={data?.parcelCount?.toMe}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*)}*/}
        {(userRole === "Administrator" || userRole === "Manager") && (
        <div className="col-xxl-2 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <ReportCard
              icon={<HandCoins color={'white'} />}
              color="tan"
              title="Отчет по затратам"
              link={RouteContainerTabs.ExportExpenses}
              // count={data?.sozdan?.total}
              // fromMe={data?.sozdan?.fromMe}
              // toMe={data?.sozdan?.toMe}
          />
        </div>
        )}
      </div>
  );
}
