import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {update} from "immupdate";
import TabPage from "../tabs/TabPage";
import MoveMoneyForm from "./MoveMoneyForm";
import {useMoveMoneyApiContext} from "../../api/money/MoveMoneyApiContext";
import {useAccountApiContext} from "../../api/account/AccountApiContext";

export default function MoveMoneyFormWrapper() {

    const {AccountApi} = useAccountApiContext();
    const {MoveMoneyApi} = useMoveMoneyApiContext();
    const [search, setSearch] = useSearchParams();

    const [accountTypes, setAccountTypes] = useState<any[]>([]);
    const [accounts, setAccounts] = useState<any[]>([]);

    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        id: "",
        accounts: "",
        fromAccountId: "",
        fromAccountName: "",
        toAccountId: "",
        toAccountName: "",
        dateCreated: new Date(),
    })

    const id = useMemo(() => search.get("moveMoneyId"), [search]);

    useEffect(() => {
        if (id) {

            MoveMoneyApi.getDetails(id)
                .then((response: any) => {
                    var date = new Date(response.dateCreatedFor);
                    setInitialValues((prev: any) => update(prev, {
                        amount: response.amount,
                        isActive: response.isActive ? {label: "Активный", value: 1} : {label: "Заблокирован", value: 2},
                        fromAccountId: {
                            label: response.fromAccountName,
                            value: response.fromAccountId
                        },
                        toAccountId: {
                            label: response.toAccountName,
                            value: response.toAccountId
                        },
                        dateCreated: date

                    }))
                })
                .catch((err: any) => toast.error(err.message))
        }
    }, [id, setInitialValues, MoveMoneyApi]);

    useEffect(() => {
        AccountApi.getAccountTypeList()
            .then((response: any) => {
                response.accountsTypeList.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setAccountTypes((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setAccountTypes, AccountApi]);

    useEffect(() => {
        AccountApi.getAccountList()
            .then((response: any) => {
                response.accounts.map((item: any) => {
                    const data = {
                        label: item.name + " (" + item.balance+ " " + item.currencyCode + ")",
                        value: item.id,
                    };
                    setAccounts((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setAccounts, AccountApi]);

    const merchantDisabled = useCallback((value: Number) => {
        if (value === 1 || value === 3 || value === 4 || value === 5 || value === 6) return true;
        else return false;
    }, []);

    const userDisabled = useCallback((value: Number) => {
        if (value === 2) return true;
        else return false;
    }, []);

    const userMerchantDisabled = useCallback((value: Number) => {
        if (value === 1) return true;
        else return false;
    }, []);

    const updateMoveMoney = (id: number, value: any) => {
        const data = {
            ...value,
            id: Number(id),
            fromAccountId: value.fromAccountId.value,
            toAccountId: value.toAccountId.value,
            amount: value.amount,
            dateCreated: value.dateCreated

        };
        MoveMoneyApi.update(data).then((response ) => {
            toast.success(response.message);
            navigate('/app/money/tableMoveMoney');
        }).catch((error) => toast.error(error.message));
    };

    const createMoveMoney = (value: any) => {
            const data = {
                ...value,
                fromAccountId: value.fromAccountId.value,
                toAccountId: value.toAccountId.value,
                amount: value.amount,

            };
            MoveMoneyApi.create(data).then((response) => {
                toast.success(response.message);

                navigate('/app/money/tableMoveMoney');

            }).catch((error) => toast.error(error.message));

    };

    const submit = useCallback((value: any) => {
        if (id) {
            updateMoveMoney(Number(id), value);
        } else {
            createMoveMoney(value);
        }
    }, [id, updateMoveMoney, createMoveMoney]);

    const accountStateOptions = [
        { value: 1, label: "Активный" },
        { value: 2, label: "Заблокирован" }
    ];
    return (
        <TabPage
            childrenClassName="p-3 pt-4"
        >
            <MoveMoneyForm
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                accounts={accounts}
                submit={submit}/>
            <div/>
        </TabPage>
    )
}